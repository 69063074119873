/*
Gibt den Dezimal- oder Gruppen-Separator zurück
Quelle: https://stackoverflow.com/questions/1074660/with-a-browser-how-do-i-know-which-decimal-separator-does-the-operating-system

separatorType: "decimal"|"group"
*/
function getSeparator(locale, separatorType) {
    const numberWithGroupAndDecimalSeparator = 1000.1
    return Intl.NumberFormat(locale)
        .formatToParts(numberWithGroupAndDecimalSeparator)
        .find(part => part.type === separatorType)
        .value
}


export function getDecimalSeparator(locale) {
    return getSeparator(locale, "decimal")
}

