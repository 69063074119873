import {Injectable} from '@angular/core'
import {ApiSocketService} from "./api.socket.service"
import {Activity} from "../app.interfaces"
import {localSessionCache} from "../storage/local-session-cache"


@Injectable({
    providedIn: 'root'
})
export class ActivityCacheService {


    constructor(
        private apiService: ApiSocketService,
    ) {
        // Auf Änderungen der Aktivitäten horchen
        this.apiService.activityUpdated.subscribe((dayIso) => {
            // Cache des übermittelten Tages löschen
            localSessionCache.deleteActivitiesDay(dayIso)
        })
    }


    getActivitiesDay(dayIso: string) {
        console.debug(`ActivityCacheService.getActivitiesDay(${dayIso})`)
        return localSessionCache.getActivitiesDay(dayIso)
    }


    setActivitiesDay(dayIso: string, activities: Activity[]) {
        console.debug(`ActivityCacheService.setActivitiesDay(${dayIso})`)
        return localSessionCache.setActivitiesDay(dayIso, activities)
    }

}
