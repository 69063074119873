<ion-app>
  <ion-split-pane contentId="main-content">
    <ion-menu contentId="main-content" type="overlay">
      <ion-content>
        <ion-menu-toggle auto-hide="false">

          <!-- Menüliste BEGIN -->
          <ion-list id="menu-list">

            <!-- Header BEGIN -->
            <ion-list-header routerLink="/" routerDirection="root" class="clickable" style="text-decoration: none;">
              <img src="/assets/logos/activity_time_recording_logo.svg" alt="{{ APPNAME }}" class="menu-list-logo"/>
              {{ APPNAME }}
            </ion-list-header>
            <div class="ion-text-center slogan">
              <ion-text color="medium">{{ "slogan" | translate }}</ion-text>
            </div>
            <!-- Header END -->

            <!-- Start BEGIN -->
            <ion-item routerDirection="root" routerLink="/start" lines="none" detail="false" routerLinkActive="selected">
              <ion-icon slot="start" name="home"></ion-icon>
              <ion-label>
                {{ "components.app_component.start.label" | translate }}
              </ion-label>
            </ion-item>
            <!-- Start END -->

            <!-- Time Recording BEGIN -->
            <ion-item
              *ngIf="loggedIn$|async"
              routerLink="/recording"
              lines="none"
              detail="false"
              routerLinkActive="selected"
              [replaceUrl]="!(isStartpage | async)"
            >
              <ion-icon slot="start" name="timer" [color]="PAGE_COLOR_RECORDING$ | async"></ion-icon>
              <ion-label>
                {{ "components.app_component.time_recording.label" | translate }}
              </ion-label>
              <ion-text
                *ngIf="recording"
                [title]="'components.app_component.time_recording.recording' | translate"
              >
                <ion-icon
                  name="ellipse"
                  color="danger"
                ></ion-icon>
              </ion-text>
            </ion-item>
            <!-- Time Recording END -->


            <!-- Fast Input BEGIN -->
            <ion-item
              *ngIf="loggedIn$|async"
              routerLink="/fast-input"
              lines="none"
              detail="false"
              routerLinkActive="selected"
              [replaceUrl]="!(isStartpage | async)"
            >
              <ion-icon slot="start" name="terminal" [color]="PAGE_COLOR_FAST_INPUT$ | async"></ion-icon>
              <ion-label>
                {{ "components.app_component.fast_input.label" | translate }}
              </ion-label>
            </ion-item>
            <!-- Fast Input END -->


            <!-- Aktivitäten BEGIN -->
            <ion-item
              *ngIf="loggedIn$|async"
              routerLink="/activities"
              lines="none"
              detail="false"
              routerLinkActive="selected"
              [replaceUrl]="!(isStartpage | async)"
            >
              <ion-icon slot="start" name="list" [color]="PAGE_COLOR_ACTIVITIES$ | async"></ion-icon>
              <ion-label>
                {{ "components.app_component.activities.label" | translate }}
              </ion-label>
            </ion-item>
            <!-- Aktivitäten END -->


            <!-- Projects BEGIN -->
            <ion-item
              *ngIf="loggedIn$|async"
              routerLink="/projects"
              lines="none"
              detail="false"
              routerLinkActive="selected"
              [replaceUrl]="!(isStartpage | async)"
            >
              <ion-icon slot="start" name="file-tray-stacked" [color]="PAGE_COLOR_PROJECTS$ | async"></ion-icon>
              <ion-label>
                {{ "components.app_component.projects.label" | translate }}
              </ion-label>
            </ion-item>
            <!-- Projects END -->


            <!-- Tags (Markierungen) BEGIN -->
            <ion-item
              *ngIf="(loggedIn$ | async) && !(isAndroid && (freeUser$ | async))"
              routerLink="/tags"
              lines="none"
              detail="false"
              routerLinkActive="selected"
              [replaceUrl]="!(isStartpage | async)"
              [disabled]="(freeUser$|async)"
            >
              <ion-icon slot="start" name="pricetags" [color]="PAGE_COLOR_TAGS$ | async"></ion-icon>
              <ion-label>
                <div>
                  {{ "components.app_component.tags.label" | translate }}
                </div>
                <div *ngIf="(freeUser$|async)">
                  <small>{{ "components.app_component.basic_plan_hint" | translate }}</small>
                </div>
              </ion-label>
            </ion-item>
            <!-- Tags END -->


            <!-- Reports BEGIN -->
            <ion-item
              *ngIf="(basicUser$|async) || (proUser$|async)"
              routerLink="/reports"
              lines="none"
              detail="false"
              routerLinkActive="selected"
              [replaceUrl]="!(isStartpage | async)"
            >
              <ion-icon slot="start" name="bar-chart" [color]="PAGE_COLOR_REPORTS$ | async"></ion-icon>
              <ion-label>
                <div>
                  {{ "components.app_component.reports.label" | translate }}
                </div>
                <div *ngIf="(freeUser$|async)">
                  <small>{{ "components.app_component.basic_plan_hint" | translate }}</small>
                </div>
              </ion-label>
            </ion-item>
            <!-- Reports END -->


            <!-- Export BEGIN -->
            <ion-item
              *ngIf="(loggedIn$ | async) && !(isAndroid && (freeUser$ | async))"
              routerLink="/export"
              lines="none"
              detail="false"
              routerLinkActive="selected"
              [replaceUrl]="!(isStartpage | async)"
              [disabled]="(freeUser$|async)"
            >
              <ion-icon slot="start" name="download" [color]="PAGE_COLOR_EXPORT$ | async"></ion-icon>
              <ion-label>
                <div>
                  {{ "components.app_component.export.label" | translate }}
                </div>
                <div *ngIf="(freeUser$|async)">
                  <small>{{ "components.app_component.basic_plan_hint" | translate }}</small>
                </div>
              </ion-label>
            </ion-item>
            <!-- Export END -->


            <!-- Pricing BEGIN -->
            <ion-item
              *ngIf="!isAndroid && (freeUser$ | async)"
              routerLink="/pricing"
              lines="none"
              detail="false"
              routerLinkActive="selected"
              [replaceUrl]="!(isStartpage | async)"
            >
              <ion-icon name="star" slot="start"></ion-icon>
              <ion-label>
                {{ "components.app_component.pricing.label" | translate }}
              </ion-label>

              <!--              <ion-badge-->
              <!--                *ngIf="freeUser | async"-->
              <!--                color="light"-->
              <!--                slot="end"-->
              <!--                title="{{ 'components.app_component.pricing.badge_note' | translate }}"-->
              <!--              >-->
              <!--                FREE-->
              <!--              </ion-badge>-->
              <!--              <ion-badge-->
              <!--                *ngIf="basicUser | async"-->
              <!--                color="light"-->
              <!--                slot="end"-->
              <!--                title="{{ 'components.app_component.pricing.badge_note' | translate }}"-->
              <!--              >-->
              <!--                BASIC-->
              <!--              </ion-badge>-->
              <!--              <ion-badge-->
              <!--                *ngIf="proUser | async"-->
              <!--                color="light"-->
              <!--                slot="end"-->
              <!--                title="{{ 'components.app_component.pricing.badge_note' | translate }}"-->
              <!--              >-->
              <!--                PRO-->
              <!--              </ion-badge>-->

            </ion-item>
            <!-- Pricing END -->


          </ion-list>
          <!-- Menüliste END -->


          <!-- Current-User Liste BEGIN -->
          <ion-list id="current-user-list" *ngIf="loggedIn$|async" class="ion-margin-top">
            <ion-note color="tertiary" class="ion-margin-bottom ion-text-wrap">{{ fullName$ | async }}</ion-note>

            <!-- Einstellungen BEGIN -->
            <ion-item
              routerLink="/settings"
              lines="none"
              detail="false"
              routerLinkActive="selected"
              [replaceUrl]="!(isStartpage | async)"
            >
              <ion-icon slot="start" name="settings"></ion-icon>
              <ion-label>
                {{ "components.app_component.settings.label" | translate }}
              </ion-label>
            </ion-item>
            <!-- Einstellungen END -->

            <!-- Logout BEGIN -->
            <ion-item lines="none" detail="false" (click)="logout()" button="true">
              <ion-icon slot="start" name="log-out"></ion-icon>
              <ion-label>
                {{ "components.app_component.log_out.label" | translate }}
              </ion-label>
            </ion-item>
            <!-- Logout END -->

          </ion-list>
          <!-- Current-User Liste BEGIN -->


          <!-- Login Liste BEGIN -->
          <ion-list id="log-in-list" *ngIf="!(loggedIn$|async)">
            <!--<ion-note>[Max Mustermann]</ion-note>-->

            <!-- Login BEGIN -->
            <ion-item lines="none" detail="false" routerLink="/login">
              <ion-icon slot="start" name="log-in"></ion-icon>
              <ion-label [innerHTML]="'components.app_component.log_in.label' | translate">
              </ion-label>
            </ion-item>
            <!-- Login END -->

          </ion-list>
          <!-- Login Liste BEGIN -->


          <!-- AUSGEBLENDET: Hinweis BEGIN -->
          <div
            *ngIf="false"
            class="ion-margin"
          >
            <ion-note>
              {{ "components.app_component.bottom_note" | translate }}
            </ion-note>
          </div>
          <!-- Hinweis END -->


          <!-- Info Liste BEGIN -->
          <ion-list id="info-list">

            <!-- Homepage BEGIN -->
            <ion-item
              lines="none"
              detail="false"
              href="https://activity-time-recording.com"
              target="_blank"
            >
              <ion-label>
                {{ "components.app_component.homepage.label" | translate }}
                <img class="link-icon" src="/assets/icons/box-arrow-up-right.svg" alt=""/>
              </ion-label>
            </ion-item>
            <!-- Homepage END -->

            <!--            &lt;!&ndash; Preise BEGIN &ndash;&gt;-->
            <!--            <ion-item-->
            <!--              lines="none"-->
            <!--              detail="false"-->
            <!--              href="https://activity-time-recording.com/pricing"-->
            <!--              target="_blank"-->
            <!--            >-->
            <!--              <ion-label>-->
            <!--                {{ "components.app_component.pricing_website.label" | translate }}-->
            <!--                <img class="link-icon" src="/assets/icons/box-arrow-up-right.svg" alt=""/>-->
            <!--              </ion-label>-->
            <!--            </ion-item>-->
            <!--            &lt;!&ndash; Preise END &ndash;&gt;-->

            <!-- Datenschutz, Privacy BEGIN -->
            <ion-item
              lines="none"
              detail="false"
              href="https://activity-time-recording.com/privacy"
              target="_blank"
            >
              <ion-label>
                {{ "components.app_component.privacy.label" | translate }}
                <img class="link-icon" src="/assets/icons/box-arrow-up-right.svg" alt=""/>
              </ion-label>
            </ion-item>
            <!-- Datenschutz, Privacy END -->

            <!-- Impressum, Imprint BEGIN -->
            <ion-item
              lines="none"
              detail="false"
              href="https://activity-time-recording.com/imprint"
              target="_blank"
            >
              <ion-label>
                {{ "components.app_component.imprint.label" | translate }}
                <img class="link-icon" src="/assets/icons/box-arrow-up-right.svg" alt=""/>
              </ion-label>
            </ion-item>
            <!-- Impressum, Imprint END -->

            <!--            &lt;!&ndash; AGBs, Terms-Of-Service BEGIN &ndash;&gt;-->
            <!--            <ion-item-->
            <!--              lines="none"-->
            <!--              detail="false"-->
            <!--              href="https://activity-time-recording.com/terms"-->
            <!--              target="_blank"-->
            <!--            >-->
            <!--              <ion-label>-->
            <!--                {{ "components.app_component.terms_of_service.label" | translate }}-->
            <!--                <img class="link-icon" src="/assets/icons/box-arrow-up-right.svg" alt=""/>-->
            <!--              </ion-label>-->
            <!--            </ion-item>-->
            <!--            &lt;!&ndash; AGBs, Terms-Of-Service END &ndash;&gt;-->

            <!--            &lt;!&ndash; Hilfe (Support) BEGIN &ndash;&gt;-->
            <!--            <ion-item-->
            <!--              lines="none"-->
            <!--              detail="false"-->
            <!--              href="https://activity-time-recording.com/help"-->
            <!--              target="_blank"-->
            <!--            >-->
            <!--              <ion-label>-->
            <!--                {{ "components.app_component.help.label" | translate }}-->
            <!--                <img class="link-icon" src="/assets/icons/box-arrow-up-right.svg" alt=""/>-->
            <!--              </ion-label>-->
            <!--            </ion-item>-->
            <!--            &lt;!&ndash; Hilfe (Support) END &ndash;&gt;-->

            <!-- About BEGIN -->
            <ion-item
              routerLink="/about"
              lines="none"
              detail="false"
              routerLinkActive="selected"
              [replaceUrl]="!(isStartpage | async)"
            >
              <ion-label>
                {{ "components.app_component.about.label" | translate }}
              </ion-label>
            </ion-item>
            <!-- About END -->

            <!-- Install BEGIN -->
            <ion-item
              *ngIf="installEnabled | async"
              lines="none"
              detail="false"
              button="true"
              [title]="'components.app_component.install.description' | translate"
              (click)="showInstallDialog()"
            >
              <ion-label>
                {{ "components.app_component.install.label" | translate }}
              </ion-label>
            </ion-item>
            <!-- Install END -->

          </ion-list>
          <!-- Info Liste END -->


          <ion-footer class="ion-margin-top">
            <ion-select
              interface="popover"
              [(ngModel)]="preferredLangId"
              [placeholder]="'pages.settings.language_settings.automatic' | translate"
              (ionChange)="onLanguageSelected()"
            >
              <!-- Automatisch BEGIN -->
              <ion-select-option value="auto">
                {{ browserLanguageFlag | async }} {{ "pages.settings.language_settings.automatic" | translate }}
              </ion-select-option>
              <!-- Automatisch END -->

              <!-- Sprachen BEGIN -->
              <ion-select-option *ngFor="let language of languages" [value]="language.id">
                {{ language.flag }} {{ language.label }}
              </ion-select-option>
              <!-- Sprachen END -->

            </ion-select>
          </ion-footer>


        </ion-menu-toggle>
      </ion-content>
    </ion-menu>
    <ion-router-outlet id="main-content"></ion-router-outlet>
  </ion-split-pane>
</ion-app>
