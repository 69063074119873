import {NgModule} from '@angular/core'
import {NoPreloading, RouterModule, Routes} from '@angular/router'
import {NotAndroidGuard} from "./guards/not-android.guard"


const routes: Routes = [
    {
        path: '',
        redirectTo: 'start',
        pathMatch: 'full'
    },
    {
        path: 'start',
        loadChildren: () => import('./pages/start/start.module').then(m => m.StartPageModule)
    },
    {
        path: 'recording',
        loadChildren: () => import('./pages/recording/recording.module').then(m => m.RecordingPageModule),
    },
    {
        path: 'fast-input',
        loadChildren: () => import('./pages/fast-input/fast-input.module').then(m => m.FastInputPageModule),
    },
    {
        path: 'activities',
        loadChildren: () => import('./pages/activities/activities.module').then(m => m.ActivitiesPageModule)
    },
    {
        path: 'projects',
        loadChildren: () => import('./pages/projects/projects.module').then(m => m.ProjectsPageModule),
    },
    {
        path: 'tags',
        loadChildren: () => import('./pages/tags/tags.module').then(m => m.TagsPageModule),
    },
    {
        path: 'reports',
        loadChildren: () => import('./pages/reports/reports.module').then(m => m.ReportsPageModule),
    },
    {
        path: 'export',
        loadChildren: () => import('./pages/export/export.module').then(m => m.ExportPageModule)
    },
    {
        path: 'settings',
        loadChildren: () => import('./pages/settings/settings.module').then(m => m.SettingsPageModule),
    },
    {
        path: 'pricing',
        loadChildren: () => import('./pages/pricing/pricing.module').then(m => m.PricingPageModule),
        canActivate: [NotAndroidGuard]
    },
    {
        path: 'about',
        loadChildren: () => import('./pages/about/about.module').then(m => m.AboutPageModule)
    },
    {
        path: 'login',
        loadChildren: () => import('./pages/auth/login/login.module').then(m => m.LoginPageModule)
    },
    {
        path: 'logged-in',
        loadChildren: () => import('./pages/auth/logged-in/logged-in.module').then(m => m.LoggedInPageModule)
    },
    {
        path: 'prepare-reset-credentials',
        loadChildren: () => import('./pages/auth/prepare-reset-credentials/prepare-reset-credentials.module').then(m => m.PrepareResetCredentialsPageModule)
    },
    {
        path: 'reset-credentials/:key',
        loadChildren: () => import('./pages/auth/reset-credentials/reset-credentials.module').then(m => m.ResetCredentialsPageModule)
    },
    {
        path: 'register',
        loadChildren: () => import('./pages/auth/register/register.module').then(m => m.RegisterPageModule)
    },
    {
        path: 'order-basic-month',
        loadChildren: () => import('./pages/order/order.module').then(m => m.OrderPageModule),
        canActivate: [NotAndroidGuard]
    },
    {
        path: 'order-basic-year',
        loadChildren: () => import('./pages/order/order.module').then(m => m.OrderPageModule),
        canActivate: [NotAndroidGuard]
    },
    {
        path: 'order-pro-month',
        loadChildren: () => import('./pages/order/order.module').then(m => m.OrderPageModule),
        canActivate: [NotAndroidGuard]
    },
    {
        path: 'order-pro-year',
        loadChildren: () => import('./pages/order/order.module').then(m => m.OrderPageModule),
        canActivate: [NotAndroidGuard]
    },
    {
        path: 'order-success',
        loadChildren: () => import('./pages/order-success/order-success.module').then(m => m.OrderSuccessPageModule),
        canActivate: [NotAndroidGuard]
    },
    {
        path: 'testpage',
        loadChildren: () => import('./pages/testpage/testpage.module').then(m => m.TestpagePageModule)
    },
]


@NgModule({
    imports: [
        RouterModule.forRoot(
            routes,
            {
                // preloadingStrategy: PreloadAllModules (=Standard von Ionic -- sollte man vielleicht später wieder aktivieren)
                preloadingStrategy: NoPreloading
            }
        )
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
