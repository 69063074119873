import {EventEmitter, Injectable, OnDestroy} from '@angular/core'
import {ApiSocketService} from "./api.socket.service"
import {DateTime} from "luxon"
import {CreateActivityParams, Activity, UpdateActivityParams} from "../app.interfaces"
import {BehaviorSubject, Subscription} from "rxjs"
import {getDurationStringFromActivity, getStartEnd} from "../tools/date.tools"
import {Platform} from "@ionic/angular"
import {ActivityCacheService} from "./activity-cache.service"
import {localSessionCache} from "../storage/local-session-cache"
import {KeycloakService} from "./keycloak.service"


@Injectable({
    providedIn: 'root'
})
export class ActivityService {

    // Wird ausgelöst, bevor eine neue Aktivität erstellt wird
    activityWillCreate = new EventEmitter<CreateActivityParams>()
    // Wird ausgelöst, bevor eine Aktivität geändert wird
    activityWillUpdate = new EventEmitter<UpdateActivityParams>()


    constructor(
        private apiService: ApiSocketService,
        private platform: Platform,
        private activityCacheService: ActivityCacheService,
        private keycloakService: KeycloakService,
    ) {
    }


    // Gibt die Aktivitäten des übergebenen Tages zurück (dayIso = Localtime)
    // Es werden keine Notizen zurückgegeben.
    // Wenn `allFields` auf `true` gesetzt wird, dann werden auch die Notizen geladen.
    async getActivitiesForDay(
        dayIso: string,
        noCache: boolean = false,
        allFields = false,
        // projectUuid: string = null,
        // tagUuids: string[] = null
    ): Promise<Activity[]> {
        let activities: Activity[] = []

        // Daten aus dem Cache laden
        if (!noCache) {
            activities = this.activityCacheService.getActivitiesDay(dayIso)
            if (activities !== null) {
                // DateTime aus ISO-String erstellen
                for (let activity of activities) {
                    activity.start_datetime = DateTime.fromISO(activity.start_datetime as any).toUTC()
                    if (activity.end_datetime) {
                        activity.end_datetime = DateTime.fromISO(activity.end_datetime as any).toUTC()
                    }
                }
                // Fertig
                return activities
            }
        }

        // Start- und End-DateTime (UTC) ermitteln
        const {startDateTimeUtc, endDateTimeUtc} = getStartEnd(dayIso)

        // Activities per API-Abfrage holen
        activities = await this.apiService.getActivitiesForTimespan(
            startDateTimeUtc, endDateTimeUtc, allFields,
            // projectUuid, tagUuids
        )

        // DateTime aus ISO-String erstellen
        for (let activity of activities) {
            activity.start_datetime = DateTime.fromISO(activity.start_datetime as any).toUTC()
            if (activity.end_datetime) {
                activity.end_datetime = DateTime.fromISO(activity.end_datetime as any).toUTC()
            }
        }

        // Daten in den Cache schreiben
        this.activityCacheService.setActivitiesDay(dayIso, activities)

        // Fertig
        return activities

    }


    async createActivity(createActivityParams: CreateActivityParams): Promise<string> {
        console.debug("ActivityService.createActivity()")

        // Datum nach UTC umwandeln
        createActivityParams = {...createActivityParams}
        createActivityParams.start_datetime = (createActivityParams.start_datetime as DateTime).toUTC().toISO()
        if (createActivityParams.end_datetime) {
            createActivityParams.end_datetime = (createActivityParams.end_datetime as DateTime).toUTC().toISO()
        }
        if (!createActivityParams.name) {
            createActivityParams.name = null
        }
        if (!createActivityParams.description) {
            createActivityParams.description = null
        }

        // Event auslösen
        this.activityWillCreate.emit(createActivityParams)

        // Erstellen
        return await this.apiService.createActivity(createActivityParams)
    }


    async getActivity(activityUuid: string): Promise<Activity> {
        console.debug("ActivityService.getActivity()")

        const activity = await this.apiService.getActivity(activityUuid)

        // DateTime aus ISO-String erstellen
        activity.start_datetime = DateTime.fromISO(activity.start_datetime as any).toUTC()
        if (activity.end_datetime) {
            activity.end_datetime = DateTime.fromISO(activity.end_datetime as any).toUTC()
        }

        // Fertig
        return activity
    }


    async deleteActivity(activityUuid: string): Promise<void> {
        console.debug("ActivityService.deleteActivity()")
        await this.apiService.deleteActivity(activityUuid)
    }


    async updateActivity(updateActivityParams: UpdateActivityParams): Promise<void> {
        console.debug("ActivityService.updateActivity()")

        // Datum nach UTC umwandeln
        updateActivityParams = {...updateActivityParams}
        if (updateActivityParams.start_datetime) {
            updateActivityParams.start_datetime = (updateActivityParams.start_datetime as DateTime).toUTC().toISO()
        }
        if (updateActivityParams.end_datetime) {
            updateActivityParams.end_datetime = (updateActivityParams.end_datetime as DateTime).toUTC().toISO()
        }

        // Event auslösen
        this.activityWillUpdate.emit(updateActivityParams)

        // Updaten
        await this.apiService.updateActivity(updateActivityParams)
    }


    // Gibt die Aktivitäten zurück, deren Aufzeichnung läuft
    // Es werden keine Notizen zurückgegeben.
    async getRecordingActivities(noCache: boolean = false): Promise<Activity[]> {
        let activities: Activity[] = []

        // Daten aus dem Cache laden
        if (!noCache) {
            activities = localSessionCache.getRecordingActivities()
            if (activities !== null) {
                // DateTime aus ISO-String erstellen
                for (let activity of activities) {
                    activity.start_datetime = DateTime.fromISO(activity.start_datetime as any).toUTC()
                    if (activity.end_datetime) {
                        activity.end_datetime = DateTime.fromISO(activity.end_datetime as any).toUTC()
                    }
                }
                // Fertig
                return activities
            }
        }

        // Activities per API-Abfrage holen
        activities = await this.apiService.getRecordingActivities()

        // DateTime aus ISO-String erstellen
        for (let activity of activities) {
            activity.start_datetime = DateTime.fromISO(activity.start_datetime as any).toUTC()
            if (activity.end_datetime) {
                activity.end_datetime = DateTime.fromISO(activity.end_datetime as any).toUTC()
            }
        }

        // Daten in den Cache schreiben
        localSessionCache.setRecordingActivities(activities)

        // Fertig
        return activities

    }


    // Gibt die Aktivitäten des übergebenen Projektes (ungecached) zurück
    // Es werden keine Notizen zurückgegeben.
    // Vorerst werden einfach nur die letzten 60 Tage zurückgegeben.
    async getActivitiesForProject(projectUuid: string): Promise<Activity[]> {
        let activities: Activity[] = []

        // Start- und End-DateTime (UTC) ermitteln
        const startDayIso = DateTime.now().toLocal().minus({days: 60}).toISODate()
        const endDayIso = DateTime.now().toLocal().plus({days: 1}).toISODate()
        const {startDateTimeUtc, endDateTimeUtc} = getStartEnd(startDayIso, endDayIso)

        // Activities per API-Abfrage holen
        activities = await this.apiService.getActivitiesForTimespan(
            startDateTimeUtc,
            endDateTimeUtc,
            false,
            projectUuid
        )

        // DateTime aus ISO-String erstellen
        for (let activity of activities) {
            activity.start_datetime = DateTime.fromISO(activity.start_datetime as any).toUTC()
            if (activity.end_datetime) {
                activity.end_datetime = DateTime.fromISO(activity.end_datetime as any).toUTC()
            }
        }

        // Fertig
        return activities

    }


    // Activities-Liste des übergebenen Tages, die sich bei Änderungen selbst aktualisiert
    getActivitiesForDaySubject(dayIso: string) {
        return new ActivitiesForDaySubject(this, this.apiService, dayIso, this.platform, this.keycloakService)
    }


    // Aktivities-Liste mit Aktivitäten ohne End-Zeit, die sich bei Änderung selbst aktualisiert
    getRecordingActivitiesSubject() {
        return new RecordingActivitiesSubject(this, this.apiService, this.platform)
    }


    // Activities-Liste des übergebenen Projektes, die sich bei Änderungen selbst aktualisiert
    getActivitiesForProjectSubject(projectUuid: string) {
        return new ActivitiesForProjectSubject(this, this.apiService, this.platform, projectUuid)
    }


    // Gibt die Aktivitäten des übergebenen Zeitraumes zurück (startDayIso = Localtime, endDayIso = Localtime)
    // Wenn `allFields` auf `true` gesetzt wird, dann werden auch die Notizen geladen.
    async getActivitiesForTimespan(startDayIso: string, endDayIso: string, allFields = false): Promise<Activity[]> {
        let activities: Activity[] = []

        // Start- und End-DateTime (UTC) ermitteln
        const {startDateTimeUtc, endDateTimeUtc} = getStartEnd(startDayIso, endDayIso)

        // Activities per API-Abfrage holen
        activities = await this.apiService.getActivitiesForTimespan(startDateTimeUtc, endDateTimeUtc, allFields)

        // DateTime aus ISO-String erstellen
        for (let activity of activities) {
            activity.start_datetime = DateTime.fromISO(activity.start_datetime as any).toUTC()
            if (activity.end_datetime) {
                activity.end_datetime = DateTime.fromISO(activity.end_datetime as any).toUTC()
            }
        }

        // Fertig
        return activities

    }


    // Gibt die mit den "Tags" markierten Aktivitäten (ungecached) zurück
    // Es werden keine Notizen zurückgegeben.
    // Vorerst werden einfach nur die letzten 60 Tage zurückgegeben.
    async getActivitiesForTags(tagUuids: string[]): Promise<Activity[]> {
        let activities: Activity[] = []

        // Start- und End-DateTime (UTC) ermitteln
        const startDayIso = DateTime.now().toLocal().minus({days: 60}).toISODate()
        const endDayIso = DateTime.now().toLocal().plus({days: 1}).toISODate()
        const {startDateTimeUtc, endDateTimeUtc} = getStartEnd(startDayIso, endDayIso)

        // Activities per API-Abfrage holen
        activities = await this.apiService.getActivitiesForTimespan(
            startDateTimeUtc,
            endDateTimeUtc,
            false,
            null,
            tagUuids
        )

        // DateTime aus ISO-String erstellen
        for (let activity of activities) {
            activity.start_datetime = DateTime.fromISO(activity.start_datetime as any).toUTC()
            if (activity.end_datetime) {
                activity.end_datetime = DateTime.fromISO(activity.end_datetime as any).toUTC()
            }
        }

        // Fertig
        return activities

    }


    // Activities-Liste der übergebenen Tags, die sich bei Änderungen selbst aktualisiert
    getActivitiesForTagsSubject(tagUuids: string[]) {
        return new ActivitiesForTagsSubject(this, this.apiService, this.platform, tagUuids)
    }

}


// Activities-Liste des Tages die sich bei Änderungen selbst aktualisiert
export class ActivitiesForDaySubject extends BehaviorSubject<Activity[]> {

    loading = new BehaviorSubject<boolean>(false)
    private readonly activityUpdatedSubscription: Subscription
    private readonly platformResumeSubscription: Subscription
    private readonly loggedInSubscription: Subscription


    constructor(
        private readonly activityService: ActivityService,
        private readonly apiService: ApiSocketService,
        private readonly dayIso: string,
        private readonly platform: Platform,
        private readonly keycloakService: KeycloakService,
    ) {
        super([])

        let loaded = false

        // Neu laden, nachdem sich ein Benutzer an- oder abgemeldet hat
        if (this.loggedInSubscription) {
            this.loggedInSubscription.unsubscribe()
        }
        this.loggedInSubscription = this.keycloakService.loggedInSubject.subscribe(async (loggedIn) => {
            if (loggedIn) {
                this.update(true)  // asynchron
            } else {
                this.next([])
            }
            loaded = true
        })

        // Auf Änderungen der Activities horchen und neu laden
        if (this.activityUpdatedSubscription) {
            this.activityUpdatedSubscription.unsubscribe()
        }
        this.activityUpdatedSubscription = this.apiService.activityUpdated.subscribe(async (dayIso) => {

            const dayLocal = DateTime.fromISO(this.dayIso).toLocal()
            const dayUtc = dayLocal.toUTC()

            if (
                dayIso === dayLocal.toISODate() ||
                dayIso === dayUtc.toISODate()
            ) {
                this.update(true)  // asynchron
                loaded = true
            }
        })

        // Neu laden, wenn das Programm wieder aktiviert wird.
        // The resume event fires when the native platform pulls the application out from the background.
        // This event emits when a Cordova/Capacitor app comes out from the background
        // but doesn't fire in a standard web browser.
        if (this.platformResumeSubscription) {
            this.platformResumeSubscription.unsubscribe()
        }
        this.platformResumeSubscription = this.platform.resume.subscribe(async () => {
            this.update(true)  // asynchron
            loaded = true
        })

        // Aktivitäten laden
        if (!loaded) {
            this.update()  // asynchron
        }
    }


    subscribe(observer?): Subscription {
        console.debug("ActivitiesForDaySubject.subscribe()")

        const subscription = super.subscribe(observer)
        const oldUnsubscribe = subscription.unsubscribe

        // Unsubscribe überschreiben
        subscription.unsubscribe = () => {
            console.debug("ActivitiesForDaySubject.Subscription.unsubscribe()")

            // Eventuell vorhandene alte Aktivitäten durchlaufen und Intervall-Timer deaktivieren
            if (this.value?.length > 0) {
                for (let activity of this.value) {
                    if (activity._durationStringSubjectTimer) {
                        clearInterval(activity._durationStringSubjectTimer)
                    }
                }
            }

            if (this.activityUpdatedSubscription) {
                this.activityUpdatedSubscription.unsubscribe()
            }
            if (this.platformResumeSubscription) {
                this.platformResumeSubscription.unsubscribe()
            }
            oldUnsubscribe.bind(subscription)()
        }

        return subscription
    }


    // Aktualisiert die Activities (asynchron)
    async update(noCache = false) {
        this.loading.next(true)

        // Eventuell vorhandene alte Aktivitäten durchlaufen und Intervall-Timer deaktivieren
        if (this.value?.length > 0) {
            for (let activity of this.value) {
                if (activity._durationStringSubjectTimer) {
                    clearInterval(activity._durationStringSubjectTimer)
                }
            }
        }

        // Aktivitäten laden
        const activities = await this.activityService.getActivitiesForDay(this.dayIso, noCache)

        // durationString ermitteln
        for (let activity of activities) {
            activity.durationStringSubject = new BehaviorSubject<string>(getDurationStringFromActivity(activity))

            // Intervall-Timer zum laufenden neuberechnen des Dauer-Strings starten
            if (!activity?.end_datetime) {
                activity._durationStringSubjectTimer = setInterval(() => {
                    activity.durationStringSubject.next(getDurationStringFromActivity(activity))
                }, 300)
            }

        }
        this.next(activities)
        this.loading.next(false)
    }

}


// Recording Activities-Liste die sich bei Änderungen selbst aktualisiert
export class RecordingActivitiesSubject extends BehaviorSubject<Activity[]> {

    loading = new BehaviorSubject<boolean>(false)
    private readonly activityUpdatedSubscription: Subscription
    private readonly platformResumeSubscription: Subscription


    constructor(
        private readonly activityService: ActivityService,
        private readonly apiService: ApiSocketService,
        private readonly platform: Platform,
    ) {
        super([])

        let loaded = false

        // Auf Änderungen der Activities horchen und neu laden
        if (this.activityUpdatedSubscription) {
            this.activityUpdatedSubscription.unsubscribe()
        }
        this.activityUpdatedSubscription = this.apiService.activityUpdated.subscribe(async () => {
            await this.update(true)  // asynchron
            loaded = true
        })

        // Neu laden, wenn das Programm wieder aktiviert wird.
        // The resume event fires when the native platform pulls the application out from the background.
        // This event emits when a Cordova/Capacitor app comes out from the background
        // but doesn't fire in a standard web browser.
        if (this.platformResumeSubscription) {
            this.platformResumeSubscription.unsubscribe()
        }
        this.platformResumeSubscription = this.platform.resume.subscribe(async () => {
            await this.update(true)  // asynchron
            loaded = true
        })

        // Aktivitäten laden
        if (!loaded) {
            this.update()  // asynchron
        }

    }


    subscribe(observer?): Subscription {
        console.debug("RecordingActivitiesSubject.subscribe()")

        const subscription = super.subscribe(observer)
        const oldUnsubscribe = subscription.unsubscribe

        // Unsubscribe überschreiben
        subscription.unsubscribe = () => {
            console.debug("RecordingActivitiesSubject.Subscription.unsubscribe()")
            if (this.activityUpdatedSubscription) {
                this.activityUpdatedSubscription.unsubscribe()
            }
            if (this.platformResumeSubscription) {
                this.platformResumeSubscription.unsubscribe()
            }
            // Unsubscribe
            oldUnsubscribe.bind(subscription)()
        }

        return subscription
    }


    // Aktualisiert die Activities (asynchron)
    async update(noCache = false) {
        this.loading.next(true)

        // Aktivitäten laden
        const activities = await this.activityService.getRecordingActivities(noCache)

        // Aktivitäten sortieren
        activities.sort((a, b) => {
            if (a.start_datetime < b.start_datetime) {
                return 1
            } else if (a.start_datetime > b.start_datetime) {
                return -1
            } else {
                return 0
            }
        })

        // Fertig
        this.next(activities)
        this.loading.next(false)
    }

}


// Activities-Liste des Projektes die sich bei Änderungen selbst aktualisiert
export class ActivitiesForProjectSubject extends BehaviorSubject<Activity[]> {

    loading = new BehaviorSubject<boolean>(false)
    private readonly activityUpdatedSubscription: Subscription
    private readonly platformResumeSubscription: Subscription


    constructor(
        private readonly activityService: ActivityService,
        private readonly apiService: ApiSocketService,
        private readonly platform: Platform,
        private projectUuid: string,
    ) {
        super([])

        let loaded = false

        // Auf Änderungen der Activities horchen und neu laden
        if (this.activityUpdatedSubscription) {
            this.activityUpdatedSubscription.unsubscribe()
        }
        this.activityUpdatedSubscription = this.apiService.activityUpdated.subscribe(async (dayIso) => {
            this.update(true)  // asynchron
            loaded = true
        })

        // Neu laden, wenn das Programm wieder aktiviert wird.
        // The resume event fires when the native platform pulls the application out from the background.
        // This event emits when a Cordova/Capacitor app comes out from the background
        // but doesn't fire in a standard web browser.
        if (this.platformResumeSubscription) {
            this.platformResumeSubscription.unsubscribe()
        }
        this.platformResumeSubscription = this.platform.resume.subscribe(async () => {
            this.update(true)  // asynchron
            loaded = true
        })

        // Aktivitäten laden
        if (!loaded) {
            this.update()  // asynchron
        }
    }


    subscribe(observer?): Subscription {
        console.debug("ActivitiesForDaySubject.subscribe()")

        const subscription = super.subscribe(observer)
        const oldUnsubscribe = subscription.unsubscribe

        // Unsubscribe überschreiben
        subscription.unsubscribe = () => {
            console.debug("ActivitiesForProjectSubject.Subscription.unsubscribe()")

            // Eventuell vorhandene alte Aktivitäten durchlaufen und Intervall-Timer deaktivieren
            if (this.value?.length > 0) {
                for (let activity of this.value) {
                    if (activity._durationStringSubjectTimer) {
                        clearInterval(activity._durationStringSubjectTimer)
                    }
                }
            }

            if (this.activityUpdatedSubscription) {
                this.activityUpdatedSubscription.unsubscribe()
            }
            if (this.platformResumeSubscription) {
                this.platformResumeSubscription.unsubscribe()
            }
            oldUnsubscribe.bind(subscription)()
        }

        return subscription
    }


    // Aktualisiert die Activities (asynchron)
    async update(noCache = false) {
        this.loading.next(true)

        // Eventuell vorhandene alte Aktivitäten durchlaufen und Intervall-Timer deaktivieren
        if (this.value?.length > 0) {
            for (let activity of this.value) {
                if (activity._durationStringSubjectTimer) {
                    clearInterval(activity._durationStringSubjectTimer)
                }
            }
        }

        // Aktivitäten laden
        const activities = await this.activityService.getActivitiesForProject(this.projectUuid)

        // durationString ermitteln
        for (let activity of activities) {
            activity.durationStringSubject = new BehaviorSubject<string>(getDurationStringFromActivity(activity))

            // Intervall-Timer zum laufenden neuberechnen des Dauer-Strings starten
            if (!activity?.end_datetime) {
                activity._durationStringSubjectTimer = setInterval(() => {
                    activity.durationStringSubject.next(getDurationStringFromActivity(activity))
                }, 300)
            }

        }
        this.next(activities)
        this.loading.next(false)
    }

}


// Activities-Liste der Tags die sich bei Änderungen selbst aktualisiert
export class ActivitiesForTagsSubject extends BehaviorSubject<Activity[]> {

    loading = new BehaviorSubject<boolean>(false)
    private readonly activityUpdatedSubscription: Subscription
    private readonly platformResumeSubscription: Subscription


    constructor(
        private readonly activityService: ActivityService,
        private readonly apiService: ApiSocketService,
        private readonly platform: Platform,
        private tagUuids: string[],
    ) {
        super([])

        let loaded = false

        // Auf Änderungen der Activities horchen und neu laden
        if (this.activityUpdatedSubscription) {
            this.activityUpdatedSubscription.unsubscribe()
        }
        this.activityUpdatedSubscription = this.apiService.activityUpdated.subscribe(async (dayIso) => {
            this.update(true)  // asynchron
            loaded = true
        })

        // Neu laden, wenn das Programm wieder aktiviert wird.
        // The resume event fires when the native platform pulls the application out from the background.
        // This event emits when a Cordova/Capacitor app comes out from the background
        // but doesn't fire in a standard web browser.
        if (this.platformResumeSubscription) {
            this.platformResumeSubscription.unsubscribe()
        }
        this.platformResumeSubscription = this.platform.resume.subscribe(async () => {
            this.update(true)  // asynchron
            loaded = true
        })

        // Aktivitäten laden
        if (!loaded) {
            this.update()  // asynchron
        }
    }


    subscribe(observer?): Subscription {
        console.debug("ActivitiesForDaySubject.subscribe()")

        const subscription = super.subscribe(observer)
        const oldUnsubscribe = subscription.unsubscribe

        // Unsubscribe überschreiben
        subscription.unsubscribe = () => {
            console.debug("ActivitiesForTagsSubject.Subscription.unsubscribe()")

            // Eventuell vorhandene alte Aktivitäten durchlaufen und Intervall-Timer deaktivieren
            if (this.value?.length > 0) {
                for (let activity of this.value) {
                    if (activity._durationStringSubjectTimer) {
                        clearInterval(activity._durationStringSubjectTimer)
                    }
                }
            }

            if (this.activityUpdatedSubscription) {
                this.activityUpdatedSubscription.unsubscribe()
            }
            if (this.platformResumeSubscription) {
                this.platformResumeSubscription.unsubscribe()
            }
            oldUnsubscribe.bind(subscription)()
        }

        return subscription
    }


    // Aktualisiert die Activities (asynchron)
    async update(noCache = false) {
        this.loading.next(true)

        // Eventuell vorhandene alte Aktivitäten durchlaufen und Intervall-Timer deaktivieren
        if (this.value?.length > 0) {
            for (let activity of this.value) {
                if (activity._durationStringSubjectTimer) {
                    clearInterval(activity._durationStringSubjectTimer)
                }
            }
        }

        // Aktivitäten laden
        const activities = await this.activityService.getActivitiesForTags(this.tagUuids)

        // durationString ermitteln
        for (let activity of activities) {
            activity.durationStringSubject = new BehaviorSubject<string>(getDurationStringFromActivity(activity))

            // Intervall-Timer zum laufenden neuberechnen des Dauer-Strings starten
            if (!activity?.end_datetime) {
                activity._durationStringSubjectTimer = setInterval(() => {
                    activity.durationStringSubject.next(getDurationStringFromActivity(activity))
                }, 300)
            }

        }
        this.next(activities)
        this.loading.next(false)
    }

}
