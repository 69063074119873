import {Injectable} from '@angular/core'
import {BehaviorSubject, Subscription} from "rxjs"
import {UpdateUserParams, User} from "../app.interfaces"
import {ApiSocketService} from "./api.socket.service"
import {Platform} from "@ionic/angular"
import {KeycloakService} from "./keycloak.service"


@Injectable({
    providedIn: 'root'
})
export class CurrentUserService {

    currentUserSubject = new BehaviorSubject<User | null>(null)
    emailSubject = new BehaviorSubject<string>("")
    fullNameSubject = new BehaviorSubject<string>("")
    userUuidSubject = new BehaviorSubject<string>(null)
    private userUpdatedSubscription: Subscription
    private platformResumeSubscription: Subscription
    private loggedInSubscription: Subscription


    constructor(
        private apiService: ApiSocketService,
        private keycloakService: KeycloakService,
        private platform: Platform,
    ) {
        this.init()
    }


    async init() {

        // Horcht auf externe Benutzeränderungen und lädt dann den Benutzer neu
        if (this.userUpdatedSubscription) {
            this.userUpdatedSubscription.unsubscribe()
        }
        this.userUpdatedSubscription = this.apiService.userUpdatedSubject.subscribe(async () => {
            if (this.keycloakService.loggedInSubject.value) {
                await this.loadCurrentUser()
            }
        })

        // Neu laden, wenn das Programm wieder aktiviert wird.
        // The resume event fires when the native platform pulls the application out from the background.
        // This event emits when a Cordova/Capacitor app comes out from the background
        // but doesn't fire in a standard web browser.
        if (this.platformResumeSubscription) {
            this.platformResumeSubscription.unsubscribe()
        }
        this.platformResumeSubscription = this.platform.resume.subscribe(async () => {
            if (this.keycloakService.loggedInSubject.value) {
                await this.loadCurrentUser()
            }
        })

        // Horcht auf Login
        if (this.loggedInSubscription) {
            this.loggedInSubscription.unsubscribe()
        }
        this.loggedInSubscription = this.keycloakService.loggedInSubject.subscribe(async (loggedIn) => {
            if (loggedIn) {
                // Eingeloggt
                await this.loadCurrentUser()
            } else {
                // Ausgeloggt
                this.currentUserSubject.next(null)
                this.emailSubject.next("")
                this.fullNameSubject.next("")
                this.userUuidSubject.next(null)
            }
        })

    }


    async loadCurrentUser() {
        console.debug("UserService.loadCurrentUser()")
        try {
            const currentUser = await this.apiService.getCurrentUser()
            this.currentUserSubject.next(currentUser)
            this.emailSubject.next(currentUser.email)
            this.fullNameSubject.next(`${currentUser.first_name} ${currentUser.last_name}`)
            this.userUuidSubject.next(currentUser.user_uuid)
        } catch (e) {
            this.currentUserSubject.next(null)
            this.emailSubject.next("")
            this.fullNameSubject.next("")
            this.userUuidSubject.next(null)
        }
    }


    async updateCurrentUser(updateUser: UpdateUserParams): Promise<void> {
        console.debug("UserService.updateCurrentUser()")
        await this.apiService.updateCurrentUser(updateUser)
    }


}
