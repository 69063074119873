import {EventEmitter, Injectable} from '@angular/core'
import {Subject} from "rxjs"
import {UpdateUserParams} from "../app.interfaces"


@Injectable({
    providedIn: 'root'
})
export class EventService {

    private _scrollToActivity: string = null
    private _scrollToActivityTimeout
    private _pulsateActivity: string = null
    private _pulsateActivityTimeout

    public projectSelectComponent_markProject = new EventEmitter<string>()
    public projectSelectComponent_focusProject = new EventEmitter<string>()
    public projectSelectComponent_filterProject = new EventEmitter<string>()

    public updateCurrentUserSubject: Subject<UpdateUserParams> = new Subject()


    constructor() {
    }


    // Setzt die UUID zu einer Activity zu der gescrollt werden soll
    set scrollToActivity(activityUuid: string) {
        // Alten Timer deaktivieren
        if (this._scrollToActivityTimeout) {
            clearTimeout(this._scrollToActivityTimeout)
            this._scrollToActivityTimeout = null
        }

        // Setzt die UUID
        this._scrollToActivity = activityUuid

        // Löscht die UUID nach kurzer Zeit wieder
        this._scrollToActivityTimeout = setTimeout(() => {
            this._scrollToActivity = null
        }, 2000)
    }


    // Holt die UUID zu einer Activity zu der gescrollt werden soll
    get scrollToActivity() {
        // Alten Timer deaktivieren
        if (this._scrollToActivityTimeout) {
            clearTimeout(this._scrollToActivityTimeout)
            this._scrollToActivityTimeout = null
        }

        // Holt sich die UUID und löscht sie
        const activityUuid = this._scrollToActivity
        this._scrollToActivity = null

        // Fertig
        return activityUuid
    }


    // Setzt die UUID zu einer Activity die kurz blinken soll
    set pulsateActivity(activityUuid: string) {
        // Alten Timer deaktivieren
        if (this._pulsateActivityTimeout) {
            clearTimeout(this._pulsateActivityTimeout)
            this._pulsateActivityTimeout = null
        }

        // Setzt die UUID
        this._pulsateActivity = activityUuid

        // Löscht die UUID nach kurzer Zeit wieder
        this._pulsateActivityTimeout = setTimeout(() => {
            this._pulsateActivity = null
        }, 2000)
    }


    // Holt die UUID zu einer Activity die kurz blinken soll
    get pulsateActivity() {
        // Alten Timer deaktivieren
        if (this._pulsateActivityTimeout) {
            clearTimeout(this._pulsateActivityTimeout)
            this._pulsateActivityTimeout = null
        }

        // Holt sich die UUID und löscht sie
        const activityUuid = this._pulsateActivity
        this._pulsateActivity = null

        // Fertig
        return activityUuid
    }


}
