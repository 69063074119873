import {Injectable} from '@angular/core'
import {BehaviorSubject} from "rxjs"


@Injectable({
    providedIn: 'root'
})
export class PwaService {

    private deferredEvent = null
    public installEnabled: BehaviorSubject<boolean> = new BehaviorSubject(false)


    constructor() {

        window.addEventListener("beforeinstallprompt", (event) => {
            event.preventDefault()
            this.deferredEvent = event
            this.installEnabled.next(true)
        })

    }


    async showInstallDialog() {
        if (!this.deferredEvent) {
            return
        }

        this.deferredEvent.prompt()
        const result = await this.deferredEvent.userChoice

        this.deferredEvent = null
        this.installEnabled.next(false)
    }


}
