import {Injectable} from '@angular/core'
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router'
import {environment} from "../../environments/environment"


@Injectable({
    providedIn: 'root'
})
export class NotAndroidGuard implements CanActivate {

    constructor(
        private router: Router,
    ) {
    }

    isAndroid = environment.build === "android"


    async canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<boolean> {
        if (this.isAndroid) {
            await this.router.navigateByUrl("/start")
            return false
        } else {
            return true
        }
    }


}
