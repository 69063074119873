// import * as Pica from "pica"
// import {MAX_IMAGE_SIZE} from "./app.constants"
// import * as sha1 from "js-sha1"


// const pica = Pica()


// const ORIGIN = window.location.origin
// const ORIGIN_LENGTH = ORIGIN.length
// const PATH_ALLOWED_CHARS = new Set("abcdefghijklmnopqrstuvwxyz/:|_-.,;()1234567890")


// Liest aus der index.html die Compiletime
export function getCompileTime(): Date {
    const metaElement = document.querySelector('head meta[name="compiletime"]')
    if (metaElement) {
        const text = metaElement.attributes.getNamedItem("content").value || null
        return new Date(text)
    }
    return null
}


// Liest aus der index.html die Deployment-Info
export function getDeploymentInfo(): string {
    const metaElement = document.querySelector('head meta[name="deployment"]')
    if (metaElement) {
        return metaElement.attributes.getNamedItem("content").value || null
    }
    return null
}


// // Bild-Datei verkleinern
// export async function compressImageFile(imageFile, maxSize = MAX_IMAGE_SIZE): Promise<File> {
//     return new Promise((resolve, reject) => {
//
//         const img = new Image()
//         let sizePercent: number
//         let width: number
//         let height: number
//
//         try {
//             img.addEventListener("load", async (event) => {
//                 if (img.width < maxSize && img.height < maxSize) {
//                     resolve(imageFile)
//                     return
//                 }
//
//                 // Größe des Zielbildes ausgerechnen.
//                 if (img.width > img.height) {
//                     sizePercent = 100.0 / img.width * maxSize
//                 } else {
//                     sizePercent = 100.0 / img.height * maxSize
//                 }
//                 width = img.width / 100.0 * sizePercent
//                 height = img.height / 100.0 * sizePercent
//
//                 // Bild verkleinern
//                 const canvas = new OffscreenCanvas(width, height)
//                 const result = await pica.resize(img, canvas)
//                 const blob = await pica.toBlob(result, "image/jpeg", 0.9)
//                 const file = new File([blob], imageFile.name, {type: "image/jpeg"})
//                 resolve(file)
//                 return
//             })
//             img.src = URL.createObjectURL(imageFile)
//         } catch (error) {
//             console.error(error)
//             reject(error)
//         }
//
//     })
// }
