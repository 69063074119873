import {getSessionStorageValue, setSessionStorageValue, removeSessionStorageItem} from "../tools/session-storage.tools"
import {Activity, Project, Report, Tag} from "../app.interfaces"


enum Key {
    ACTIVITIES_DAY = "activitiesDayCache",
    PROJECTS = "projectsCache",
    REPORTS = "reportsCache",
    RECORDING_ACTIVITIES = "recordingActivitiesCache",
    TAGS = "tagsCache",
}


export namespace localSessionCache {

    export function getActivitiesDay(dayIso: string) {
        return getSessionStorageValue<Activity[]>(`${Key.ACTIVITIES_DAY}_${dayIso}`)
    }


    export function setActivitiesDay(dayIso: string, activities: Activity[], ttlSeconds: number = 20) {
        setSessionStorageValue(`${Key.ACTIVITIES_DAY}_${dayIso}`, activities, ttlSeconds)
    }


    export function deleteActivitiesDay(dayIso: string) {
        removeSessionStorageItem(`${Key.ACTIVITIES_DAY}_${dayIso}`)
    }


    export function getProjects() {
        return getSessionStorageValue<Project[]>(Key.PROJECTS)
    }


    export function setProjects(projects: Project[], ttlSeconds: number = 20) {
        setSessionStorageValue(Key.PROJECTS, projects, ttlSeconds)
    }


    export function getRecordingActivities() {
        return getSessionStorageValue<Activity[]>(Key.RECORDING_ACTIVITIES)
    }


    export function setRecordingActivities(activities: Activity[], ttlSeconds: number = 2) {
        setSessionStorageValue(Key.RECORDING_ACTIVITIES, activities, ttlSeconds)
    }


    export function getReports() {
        return getSessionStorageValue<Report[]>(Key.REPORTS)
    }


    export function setReports(reports: Report[], ttlSeconds: number = 60) {
        setSessionStorageValue(Key.REPORTS, reports, ttlSeconds)
    }


    export function getTags() {
        return getSessionStorageValue<Tag[]>(Key.TAGS)
    }


    export function setTags(tags: Tag[], ttlSeconds: number = 20) {
        setSessionStorageValue(Key.TAGS, tags, ttlSeconds)
    }


}

