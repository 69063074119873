import {Injectable} from '@angular/core'
import {BehaviorSubject} from "rxjs"
import {Router, NavigationEnd} from "@angular/router"


@Injectable({
    providedIn: 'root'
})
export class NavigationService {


    isStartpage = new BehaviorSubject<boolean>(true)
    private currentUrl: string


    constructor(
        private router: Router
    ) {
        this.initService()
    }


    private async initService() {

        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                // Starseitenerkennung
                if (this.currentUrl !== event.url) {
                    this.isStartpage.next(event.url === "/start" || event.url === "/" || event.url === "")
                    this.currentUrl = event.url
                }
            }
        })

    }


}
