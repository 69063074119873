import {Inject, Injectable} from '@angular/core'
import {DOCUMENT} from "@angular/common"
import {TranslateService} from "@ngx-translate/core"
import langEn from "../../assets/i18n/en.json"
import langDe from "../../assets/i18n/de.json"
import {BehaviorSubject, Subject} from "rxjs"
import {UpdateUserParams} from "../app.interfaces"
import {KeycloakService} from "./keycloak.service"
import {localSystemSettings} from "../storage/local-system-settings"
import {EventService} from "./event.service"


@Injectable({
    providedIn: 'root'
})
export class LanguageService {

    // Flaggen: https://emojipedia.org/flags/
    public languages = [
        {id: "en", label: "English", flag: "🇺🇸"},
        {id: "de", label: "Deutsch", flag: "🇩🇪"},
        {id: "fr", label: "Français", flag: "🇫🇷"},
    ]
    private languageIds = new Set(this.languages.map((item) => item.id))
    private translations = new Map()
    public currentPreferredLanguage = new BehaviorSubject<string>("auto")
    public currentLanguage = new BehaviorSubject<string>("en")
    public browserLanguage = new BehaviorSubject<string>(null)
    public browserLanguageFlag = new BehaviorSubject<string>(null)
    public languageSelected = new Subject<string>()


    constructor(
        @Inject(DOCUMENT) private document: Document,
        private translateService: TranslateService,
        private keycloakIonicService: KeycloakService,
        private eventService: EventService,
    ) {
        this.initService()
    }


    private async initService() {

        // Spracheinstellungen (die wichtigsten Sprachen werden vorgeladen)
        this.translateService.setTranslation("en", langEn)
        this.translateService.setTranslation("de", langDe)
        this.translateService.setDefaultLang("en")

        // Auf Änderungen in den Sprachen horchen
        this.translateService.onDefaultLangChange.subscribe(async () => {
            console.debug("LanguageService.__onDefaultLangChange__()")
            await this.onLangChangesHandler()
        })
        this.translateService.onLangChange.subscribe(async () => {
            console.debug("LanguageService.__onLangChange__()")
            await this.onLangChangesHandler()
        })
        this.translateService.onTranslationChange.subscribe(async () => {
            console.debug("LanguageService.__onTranslationChange__()")
            await this.onLangChangesHandler()
        })
        this.keycloakIonicService.userUuidSubject.subscribe(async () => {
            console.debug("LanguageService.__userUuidSubject__()")
            await this.checkAndSetLanguage()
        })

        // Sprache ermitteln und einstellen
        await this.checkAndSetLanguage()

    }


    private async checkAndSetLanguage() {
        // Sprache des Browsers ermitteln
        const browserLang = await this.getBrowserLanguage()
        if (browserLang) {
            this.browserLanguage.next(browserLang)
            this.browserLanguageFlag.next(
                this.languages.filter((item) => item.id === browserLang)[0].flag
            )
        }

        // Bevorzugte Sprache ermitteln
        const preferredLanguage = this.getPreferredLanguage()
        this.currentPreferredLanguage.next(preferredLanguage || "auto")

        // Bevorzugte Sprache einstellen
        if (preferredLanguage && this.languageIds.has(preferredLanguage)) {
            await this.setLanguage(preferredLanguage)
        } else {
            // Sprache des Browsers einstellen
            if (browserLang) {
                await this.setLanguage(browserLang)
            } else {
                // Standardsprache einstellen
                await this.setLanguage("en")
            }
        }

    }


    private async onLangChangesHandler() {
        console.debug("LanguageService.onLangChangesHandler()")

        // Alle bereits verwendeten Übersetzungen neu übermitteln
        for (let key of this.translations.keys()) {
            this.translations.get(key).next(this.translateService.instant(key))
        }
    }


    async getBrowserLanguage() {

        // Sprachen mit Region durchlaufen.
        for (let browserLang of navigator.languages) {
            browserLang = browserLang.toLowerCase()
            if (this.languageIds.has(browserLang)) {
                // Sprache einstellen
                return browserLang
            }
        }

        // Sprache konnte nicht ermittelt werden.
        // Nochmal durchlaufen, aber diesmal ohne Region.
        for (let browserLang of navigator.languages) {
            browserLang = browserLang.slice(0, 2).toLowerCase()
            if (this.languageIds.has(browserLang)) {
                // Sprache einstellen
                return browserLang
            }
        }

    }


    async setLanguage(langId: string) {
        if (langId === this.translateService.currentLang) {
            return
        }
        console.debug(`LanguageService.setLanguage("${langId}")`)

        // Prüfen
        if (!this.languageIds.has(langId)) {
            langId = "en"
        }

        // Übersetzungen laden
        this.translateService.use(langId)
        this.document.documentElement.lang = langId

        // Aktuelle Sprache weitergeben
        this.currentLanguage.next(langId)
    }


    /*
    Gibt ein BehaviorSubject für jeden übergebenen Key zurück.
    */
    get(key: string): BehaviorSubject<string> {
        // Falls es noch kein BehaviorSubject für den Key gibt, wird dieses angelegt.
        if (!this.translations.has(key)) {
            this.translateService.get(key).subscribe((value) => {
                this.translations.set(key, new BehaviorSubject<string>(value))
            })
        }
        // Fertig
        return this.translations.get(key)
    }


    setPreferredLanguage(langId: string) {
        // Prüfen ob die übergebene Sprache in der Liste steht
        if (!this.languageIds.has(langId)) {
            langId = "en"
        }

        // Speichern
        localSystemSettings.setPreferredLanguage(langId)

        // Änderung der bevorzugten Sprache mitteilen
        this.currentPreferredLanguage.next(langId)
    }


    getPreferredLanguage(): string {
        return localSystemSettings.getPreferredLanguage()
    }


    deletePreferredLanguage() {
        localSystemSettings.setPreferredLanguage("auto")

        // Änderung der bevorzugten Sprache mitteilen
        this.currentPreferredLanguage.next("auto")
    }


    // Wenn irgendwo im Programm die gewünschte Sprache ausgewählt wurde, dann wird
    // dieser Handler aufgerufen.
    async onLanguageSelected(langId: string) {

        // Prüfen
        if (!this.languageIds.has(langId)) {
            langId = "auto"
        }

        let language = langId

        if (langId === "auto") {
            // Bevorzugte Sprache auf "auto" setzen
            this.deletePreferredLanguage()

            // Browser-Sprache einstellen
            const browserLang = await this.getBrowserLanguage()
            if (browserLang) {
                await this.setLanguage(browserLang)
                language = browserLang
            } else {
                // Standardsprache einstellen
                await this.setLanguage("en")
                language = "en"
            }
        } else {
            // Bevorzugte Sprache einstellen
            await this.setLanguage(langId)
            this.setPreferredLanguage(langId)
        }

        // Sprachwechsel weiterleiten
        this.languageSelected.next(language)


        // Sprache an den Server übermitteln
        // ACHTUNG! Nicht über das UserService, sondern direkt über das API-Service.
        const user_uuid = this.keycloakIonicService.userUuidSubject.value
        if (!!user_uuid) {
            const updateUserParams: UpdateUserParams = {
                user_uuid,
                language
            }
            // UserService wartet auf ein Event, welches `updateCurrentUser` ausführt.
            // Damit wird das Updaten vom LanguageService abgetrennt um Circular Dependencies zu vermeiden.
            this.eventService.updateCurrentUserSubject.next(updateUserParams)
        }

    }

}
