import moment from 'moment'
import {DateTime, Duration} from "luxon"
import {getDecimalSeparator} from "./locale.tools"
import {Activity} from "../app.interfaces"
import {DefaultTimespan} from "../app.constants"


/*
Wandelt einen Zeit-String in ein Luxon-DateTime-Objekt um.

Mögliche Strings:

    10:20:30, 1:20:30, 1:2:3
    10:20, 1:20, 10:2
    10.20.30, 10.2.30, 10.20.3, 1.2.30, 1.2.3
    10.20, 10.2, 1.20
    10 20 30, 10 2 30, 10 20 3, 1 2 30, 1 2 3
    10 20, 10 2, 1 20
    102030, 010203
    10203 -> 01:02:03
    1020
    120 -> 01:20:00
    10 -> 10:00:00
    1 -> 01:00:00

Kann der String nicht umgewandelt werden, wird `null` zurückgegeben.
*/
export function timeStringToDateTime(timeString: string): DateTime {

    // Prüfen und Leerzeichen entfernen
    if (!timeString) {
        return null
    }
    timeString = timeString.trim().replace(/\s\s+/g, " ")
    if (!timeString) {
        return null
    }
    const timeArray = Array.from(timeString)
    const length = timeString.length
    const dateString = "1970-01-01 "
    const dateTimeString = dateString + timeString


    if (timeArray.includes(":")) {
        // ":" als Separator
        const quantity = timeArray.filter((item) => item === ":").length
        if (quantity === 2) {
            // 10:20:30, 1:20:30, 1:2:3
            const result = DateTime.fromFormat(dateTimeString, "yyyy-MM-dd h:m:s")
            if (result.isValid) return result
        } else if (quantity === 1) {
            // 10:20, 1:20, 10:2
            const result = DateTime.fromFormat(dateTimeString, "yyyy-MM-dd h:m")
            if (result.isValid) return result
        }
    } else if (timeArray.includes(".")) {
        // "." als Separator
        const quantity = timeArray.filter((item) => item === ".").length
        if (quantity === 2) {
            // 10.20.30, 10.2.30, 10.20.3, 1.2.30, 1.2.3
            const result = DateTime.fromFormat(dateTimeString, "yyyy-MM-dd h.m.s")
            if (result.isValid) return result
        } else if (quantity === 1) {
            // 10.20, 10.2, 1.20
            const result = DateTime.fromFormat(dateTimeString, "yyyy-MM-dd h.m")
            if (result.isValid) return result
        }
    } else if (timeArray.includes(",")) {
        // "," als Separator - das wäre sehr merkwürdig :-)
        const quantity = timeArray.filter((item) => item === ",").length
        if (quantity === 2) {
            // 10,20,30; 10,2,30; 10,20,3; 1,2,30; 1,2,3
            const result = DateTime.fromFormat(dateTimeString, "yyyy-MM-dd h,m,s")
            if (result.isValid) return result
        } else if (quantity === 1) {
            // 10,20; 10,2; 1,20
            const result = DateTime.fromFormat(dateTimeString, "yyyy-MM-dd h,m")
            if (result.isValid) return result
        }
    } else if (timeArray.includes(" ")) {
        // " " als Separator
        const quantity = timeArray.filter((item) => item === " ").length
        if (quantity === 2) {
            // 10 20 30, 10 2 30, 10 20 3, 1 2 30, 1 2 3
            const result = DateTime.fromFormat(dateTimeString, "yyyy-MM-dd h m s")
            if (result.isValid) return result
        } else if (quantity === 1) {
            // 10 20, 10 2, 1 20
            const result = DateTime.fromFormat(dateTimeString, "yyyy-MM-dd h m")
            if (result.isValid) return result
        }
    } else {
        // Ohne Separator
        if (length === 6) {
            // 102030, 010203
            const result = DateTime.fromFormat(dateTimeString, "yyyy-MM-dd hhmmss")
            if (result.isValid) return result
        } else if (length === 5) {
            // 10203 -> 01:02:03
            const result = DateTime.fromFormat(dateTimeString, "yyyy-MM-dd hmmss")
            if (result.isValid) return result
        } else if (length === 4) {
            // 1020
            const result = DateTime.fromFormat(dateTimeString, "yyyy-MM-dd hhmm")
            if (result.isValid) return result
        } else if (length === 3) {
            // 120 -> 01:20:00
            const result = DateTime.fromFormat(dateTimeString, "yyyy-MM-dd hmm")
            if (result.isValid) return result
        } else if (length === 2) {
            // 10 -> 10:00:00
            const result = DateTime.fromFormat(dateTimeString, "yyyy-MM-dd hh")
            if (result.isValid) return result
        } else if (length === 1) {
            // 1 -> 01:00:00
            const result = DateTime.fromFormat(dateTimeString, "yyyy-MM-dd h")
            if (result.isValid) return result
        }
    }

    // String konnte nicht umgewandelt werden
    return null
}


/*
Wandelt einen Dauer-String in ein Luxon-Duration-Objekt um.

Mögliche Strings:

    1h 2m 3s
    3s 2m 1h --> 1h 2m 3s in falscher Reihenfolge
    1h2m3s
    1h
    1h 2m
    1h2m
    2h 3s
    1h3s
    2h 3s
    2m3s
    2m
    3s

    200m --> 3h 20m
    300s --> 5m

    // Doppelpunkt ist immer ein Zeittrennzeichen
    1:2:3 --> 1h 2m 3s
    01:02:03 --> 1h 2m 3s
    1:2 --> 1h 2m
    1:02 --> 1h 2m
    01:02 --> 1h 2m

    // Zwei Trennzeichen sind immer als Zeittrennzeichen erkennbar
    1.2.3 --> 1h 2m 3s
    01.02.03 --> 1h 2m 3s
    1 2 3 --> 1h 2m 3s

    // Falls das Dezimaltrennzeichen der aktuellen Sprache ein Beistrich ist
    1,5 --> 1h 30m
    // wenn nicht, dann wird der String als Stunden und Minuten interpretiert
    1,5 --> 1h 5m

    // Falls das Dezimaltrennzeichen der aktuellen Sprache ein Punkt ist
    1.5 --> 1h 30m
    // wenn nicht, dann wird der String als Stunden und Minuten interpretiert
    1.2 --> 1h 2m
    1.02 --> 1h 2m
    01.02 --> 1h 2m

Kann der String nicht umgewandelt werden, wird `null` zurückgegeben.
Es sind nur positive Zahlen möglich.
*/
export function durationStringToDuration(durationString: string): Duration {

    const H = "h"  // Einheitszeichen könnte später in anderen Sprachen ein anderes Zeichen sein...
    const M = "m"  // Einheitszeichen könnte später in anderen Sprachen ein anderes Zeichen sein...
    const S = "s"  // Einheitszeichen könnte später in anderen Sprachen ein anderes Zeichen sein...

    // Prüfen und Leerzeichen entfernen
    if (!durationString) {
        return null
    }
    durationString = durationString.trim().toLowerCase().replace(/\s\s+/g, " ")
    if (!durationString) {
        return null
    }
    if (durationString.indexOf("-") > -1) {
        return null
    }
    const durationArray = Array.from(durationString)

    if (durationArray.includes(":")) {
        // 1:2:3 --> 1h 2m 3s, 01:02:03 --> 1h 2m 3s, 1:2 --> 1h 2m, 1:02 --> 1h 2m, 01:02 --> 1h 2m

        // Leerzeichen entfernen und splitten
        durationString = durationString.replace(/\s/g, "")
        const splitted = durationString.split(":")

        // Je nach Anzahl umwandeln
        const hours = parseInt(splitted[0], 10)
        const minutes = parseInt(splitted[1], 10)
        let seconds = 0
        if (splitted.length === 3) {
            seconds = parseInt(splitted[2], 10)
        }
        try {
            const result = Duration.fromObject({hours, minutes, seconds}, {locale: navigator.language})
            if (result.isValid) return result
        } catch (e) {
            if (!(e.toString().toLowerCase().indexOf("invalid unit value") > -1)) {
                throw e
            }
        }
    } else if (
        durationArray.includes(H) ||
        durationArray.includes(M) ||
        durationArray.includes(S)
    ) {
        // 1h 2m 3s, 3s 2m 1h --> 1h 2m 3s in falscher Reihenfolge, 1h2m3s, 1h, 1h 2m, 1h2m, 2h 3s
        // 1h3s, 2h 3s, 2m3s, 2m, 3s, 200m --> 3h 20m, 300s --> 5m

        // Leerzeichen entfernen
        durationString = durationString.replace(/\s/g, "")

        // Stunden ermitteln
        let hours = 0
        const regExpH = new RegExp(`(\\d*?)${H}`)
        const hoursMatch = durationString.match(regExpH)
        if (!!hoursMatch) {
            hours = parseInt(hoursMatch[1])
        }

        // Minuten ermitteln
        let minutes = 0
        const regExpM = new RegExp(`(\\d*?)${M}`)
        const minutesMatch = durationString.match(regExpM)
        if (!!minutesMatch) {
            minutes = parseInt(minutesMatch[1])
        }

        // Sekunden ermitteln
        let seconds = 0
        const regExpS = new RegExp(`(\\d*?)${S}`)
        const secondsMatch = durationString.match(regExpS)
        if (!!secondsMatch) {
            seconds = parseInt(secondsMatch[1])
        }

        // Umwandeln
        try {
            const result = Duration.fromObject({hours, minutes, seconds}, {locale: navigator.language})
            if (result.isValid) return result
        } catch (e) {
            if (!(e.toString().toLowerCase().indexOf("invalid unit value") > -1)) {
                throw e
            }
        }

    } else if (durationArray.includes(".")) {
        // Leerzeichen entfernen und splitten
        durationString = durationString.replace(/\s/g, "")
        const splitted = durationString.split(".")

        if (splitted.length === 3) {
            // Zwei Trennzeichen sind immer als Zeittrennzeichen erkennbar
            // 1.2.3 --> 1h 2m 3s
            // 01.02.03 --> 1h 2m 3s
            const hours = parseInt(splitted[0], 10)
            const minutes = parseInt(splitted[1], 10)
            const seconds = parseInt(splitted[2], 10)
            try {
                const result = Duration.fromObject({hours, minutes, seconds}, {locale: navigator.language})
                if (result.isValid) return result
            } catch (e) {
                if (!(e.toString().toLowerCase().indexOf("invalid unit value") > -1)) {
                    throw e
                }
            }
        } else if (splitted.length === 2) {

            const decimalSeparator = getDecimalSeparator(navigator.language)
            if (decimalSeparator === ".") {
                // Falls das Dezimaltrennzeichen der aktuellen Sprache ein Punkt ist
                // 1.5 --> 1h 30m
                const hours = parseFloat(durationString)
                try {
                    const result = Duration.fromObject({hours}, {locale: navigator.language})
                    if (result.isValid) return result
                } catch (e) {
                    if (!(e.toString().toLowerCase().indexOf("invalid unit value") > -1)) {
                        throw e
                    }
                }
            } else {
                // Ansonsten wie Zeittrennzeichen behandeln
                // 1.2 --> 1h 2m, 1.02 --> 1h 2m, 01.02 --> 1h 2m
                const hours = parseInt(splitted[0], 10)
                const minutes = parseInt(splitted[1], 10)
                try {
                    const result = Duration.fromObject({hours, minutes}, {locale: navigator.language})
                    if (result.isValid) return result
                } catch (e) {
                    if (!(e.toString().toLowerCase().indexOf("invalid unit value") > -1)) {
                        throw e
                    }
                }
            }

        }

    } else if (durationArray.includes(" ")) {
        // 1 2 3 --> 1h 2m 3s
        // Splitten
        const splitted = durationString.split(" ")

        // Je nach Anzahl umwandeln
        const hours = parseInt(splitted[0], 10)
        const minutes = parseInt(splitted[1], 10)
        let seconds = 0
        if (splitted.length === 3) {
            seconds = parseInt(splitted[2], 10)
        }
        try {
            const result = Duration.fromObject({hours, minutes, seconds}, {locale: navigator.language})
            if (result.isValid) return result
        } catch (e) {
            if (!(e.toString().toLowerCase().indexOf("invalid unit value") > -1)) {
                throw e
            }
        }
    } else if (durationArray.includes(",")) {
        // Leerzeichen entfernen und splitten
        durationString = durationString.replace(/\s/g, "")
        const splitted = durationString.split(",")

        if (splitted.length === 3) {
            // Zwei Trennzeichen sind immer als Zeittrennzeichen erkennbar
            // 1,2,3 --> 1h 2m 3s
            // 01,02,03 --> 1h 2m 3s
            const hours = parseInt(splitted[0], 10)
            const minutes = parseInt(splitted[1], 10)
            const seconds = parseInt(splitted[2], 10)
            try {
                const result = Duration.fromObject({hours, minutes, seconds}, {locale: navigator.language})
                if (result.isValid) return result
            } catch (e) {
                if (!(e.toString().toLowerCase().indexOf("invalid unit value") > -1)) {
                    throw e
                }
            }

        } else if (splitted.length === 2) {

            const decimalSeparator = getDecimalSeparator(navigator.language)
            if (decimalSeparator === ",") {
                // Falls das Dezimaltrennzeichen der aktuellen Sprache ein Beistrich ist
                // 1,5 --> 1h 30m
                const hoursString = durationString.replace(",", ".")
                const hours = parseFloat(hoursString)
                try {
                    const result = Duration.fromObject({hours}, {locale: navigator.language})
                    if (result.isValid) return result
                } catch (e) {
                    if (!(e.toString().toLowerCase().indexOf("invalid unit value") > -1)) {
                        throw e
                    }
                }
            } else {
                // Ansonsten wie Zeittrennzeichen behandeln und als Stunde und Minute umwandeln
                const hours = parseInt(splitted[0], 10)
                const minutes = parseInt(splitted[1], 10)
                try {
                    const result = Duration.fromObject({hours, minutes}, {locale: navigator.language})
                    if (result.isValid) return result
                } catch (e) {
                    if (!(e.toString().toLowerCase().indexOf("invalid unit value") > -1)) {
                        throw e
                    }
                }
            }

        }

    } else if (parseInt(durationString).toString(10) === durationString) {
        // Wenn nur "eine" Zahl ist, dann handelt es sich um Stunden
        // 1 --> 1 Stunde
        const hours = parseInt(durationString)
        try {
            const result = Duration.fromObject({hours}, {locale: navigator.language})
            if (result.isValid) return result
        } catch (e) {
            if (!(e.toString().toLowerCase().indexOf("invalid unit value") > -1)) {
                throw e
            }
        }
    }

    // String konnte nicht umgewandelt werden
    return null
}


/*
Nimmt ein Duration-Objekt und gibt die Dauer als String zurück
*/
export function durationToString(duration: Duration): string {
    if (duration === null) {
        return ""
    }
    if (duration.toMillis() === 0) {
        return "0"
    }

    const {hours, minutes, seconds} = duration.shiftTo("hours", "minutes", "seconds").toObject()
    const hoursString = !!hours ? `${hours}h` : ""
    const minutesString = !!minutes ? `${minutes}m` : ""
    const secondsString = !!seconds ? `${seconds}s` : ""

    let durationString = hoursString + " " + minutesString
    durationString = durationString.trim() + " " + secondsString

    return durationString.trim()
}


/*
Wandelt das DateTime-Objekt in einen einfachen "lokalen" Zeitstring um
*/
export function dateTimeToTimeString(dateTime: DateTime, withSeconds: boolean = false): string {
    if (!dateTime) {
        return null
    }
    return dateTime.toLocal().toLocaleString(
        withSeconds ? DateTime.TIME_24_WITH_SECONDS : DateTime.TIME_24_SIMPLE
    )
}


export function durationFromDates(startDateTime: DateTime, endDateTime: DateTime): Duration {
    if (!startDateTime?.isValid || !endDateTime?.isValid) {
        return null
    }
    return Duration.fromMillis(endDateTime.toMillis() - startDateTime.toMillis())
}


export function getDurationFromActivity(activity: Activity) {
    let endDateTime: DateTime
    const startDateTime = activity.start_datetime
    if (activity?.end_datetime) {
        endDateTime = activity.end_datetime
    } else {
        endDateTime = DateTime.now()
    }
    // Fertig
    return Duration.fromObject({"seconds": Math.floor(endDateTime.diff(startDateTime).as("seconds"))})
}


export function getDurationStringFromActivity(activity: Activity) {
    const diff = getDurationFromActivity(activity)
    return durationToString(diff)
}


/*
Gibt den Start- und den End-Zeitpunkt (UTC) des übergebenen Tages oder der übergebenen Tage zurück
Der übergebene ISO-Tag ist in der lokalen Zeitzone.
Ein Tag ist `startDateTime >= DAY < endDateTime`
WICHTIG! An endDayIso wird IMMER 1 Tag hinzugefügt.
*/
export function getStartEnd(
    startDayIso: string,
    endDayIso: string = null
): {startDateTimeUtc: DateTime, endDateTimeUtc: DateTime} {

    let endDateTime: DateTime
    const startDateTime = DateTime.fromISO(startDayIso)
    if (endDayIso) {
        endDateTime = DateTime.fromISO(endDayIso).plus({days: 1})
    } else {
        endDateTime = startDateTime.plus({days: 1})
    }

    // Fertig
    return {
        startDateTimeUtc: startDateTime.toUTC(),
        endDateTimeUtc: endDateTime.toUTC()
    }
}


export function getFirstDayOfWeek(locale: string): number {
    // 0 = Sonntag
    // 1 = Montag
    return moment().locale(locale).weekday(0).day()
}


/*
Gibt den gewünschten Zeitraum als DateTime-Array zurück.
Achtung! Diese Funktion ist nicht auf Geschwindigkeit getrimmt.
*/
export function getTimespanDateTimes(locale: string, timespan: DefaultTimespan): [DateTime, DateTime] {

    const thisWeekStartMoment = moment().locale(locale).weekday(0)
    const thisWeekEndMoment = moment().locale(locale).weekday(6)
    const thisWeekStart = DateTime.fromISO(thisWeekStartMoment.toISOString()).set({hour: 0, minute: 0, second: 0, millisecond: 0})
    const thisWeekEnd = DateTime.fromISO(thisWeekEndMoment.toISOString()).set({hour: 0, minute: 0, second: 0, millisecond: 0})
    const lastWeekStart = thisWeekStart.minus({week: 1})
    const lastWeekEnd = thisWeekEnd.minus({week: 1})

    const thisMonthStart = DateTime.now().startOf("month")
    const thisMonthEnd = thisMonthStart.endOf("month")
    const lastMonthStart = thisMonthStart.minus({month: 1})
    const lastMonthEnd = lastMonthStart.endOf("month")

    const thisYearStart = DateTime.now().startOf("year")
    const thisYearEnd = thisYearStart.endOf("year")
    const lastYearStart = thisYearStart.minus({year: 1})
    const lastYearEnd = lastYearStart.endOf("year")

    if (timespan === DefaultTimespan.LAST_YEAR) {
        console.debug("DefaultTimespan.LAST_YEAR")
        return [lastYearStart, lastYearEnd]
    } else if (timespan === DefaultTimespan.THIS_YEAR) {
        console.debug("DefaultTimespan.THIS_YEAR")
        return [thisYearStart, thisYearEnd]
    } else if (timespan === DefaultTimespan.LAST_MONTH) {
        console.debug("DefaultTimespan.LAST_MONTH")
        return [lastMonthStart, lastMonthEnd]
    } else if (timespan === DefaultTimespan.THIS_MONTH) {
        console.debug("DefaultTimespan.THIS_MONTH")
        return [thisMonthStart, thisMonthEnd]
    } else if (timespan === DefaultTimespan.LAST_WEEK) {
        console.debug("DefaultTimespan.LAST_WEEK")
        return [lastWeekStart, lastWeekEnd]
    } else if (timespan === DefaultTimespan.THIS_WEEK) {
        console.debug("DefaultTimespan.THIS_WEEK")
        return [thisWeekStart, thisWeekEnd]
    }

}


// TEST
export function testItNow() {

    console.log(
        durationToString(
            durationStringToDuration("10.5.6")
        )
    )

}
