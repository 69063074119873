export const EVENT_KEYCLOAK_USER_UPDATED = "keycloak_user_updated"
export const EVENT_KEYCLOAK_USER_PLAN_CHANGED = "keycloak_user_plan_changed"
export const EVENT_KEYCLOAK_USER_DELETED = "keycloak_user_deleted"
export const EVENT_USER_UPDATED = "user_updated"
export const EVENT_PROJECT_UPDATED = "project_updated"
export const EVENT_REPORT_UPDATED = "report_updated"
export const EVENT_ACTIVITY_UPDATED = "activity_updated"
export const EVENT_SETTING_UPDATED = "setting_updated"
export const EVENT_TAG_UPDATED = "tag_updated"
export const EVENT_ROOM_JOINED = "room_joined"


export const DAY_MILLISECONDS = 86400000


export enum StripePlan {
    FREE = "free",
    BASIC_MONTH = "basic-month",
    BASIC_YEAR = "basic-year",
    PRO_MONTH = "pro-month",
    PRO_YEAR = "pro-year",
}


export enum TimeEntriesListButtonLabels {
    AUTO = "auto",
    TEXT = "text",
    ICON = "icon",
    ICON_TEXT = "icon_text",
}


export enum DefaultTimespan {
    LAST_YEAR,
    THIS_YEAR,
    LAST_MONTH,
    THIS_MONTH,
    LAST_WEEK,
    THIS_WEEK,
}


export enum SortOrder {
    ASCENDING,
    DESCENDING,
}


export enum AutostopRecordings {
    STOP,
    NOT_STOP,
    ASK
}


export const PLATFORM_NAMES_SHORTCUTS = {
    // Muss mit dem Dictionary in der API übereinstimmen.
    "android": "a",
    "capacitor": "ca",
    "cordova": "c",
    "ios": "i",
    "ipad": "ia",
    "iphone": "ip",
    "phablet": "ph",
    "tablet": "t",
    "electron": "e",
    "pwa": "p",
    "mobile": "m",
    "mobileweb": "mw",
    "desktop": "d",
    "hybrid": "hy",
}

export enum FabPosition {
    HIDDEN = "hidden",
    TOP_LEFT = "top_left",
    TOP_RIGHT = "top_right",
    BOTTOM_LEFT = "bottom_left",
    BOTTOM_RIGHT = "bottom_right",
}
