import {Injectable, NgZone} from '@angular/core'
import {AlertController, AlertButton, ToastButton, ToastController} from "@ionic/angular"
import {LanguageService} from "./language.service"
import {marker as _} from '@biesbjerg/ngx-translate-extract-marker'


@Injectable({
    providedIn: 'root'
})
export class NotificationService {

    constructor(
        private alertController: AlertController,
        private toastController: ToastController,
        private languageService: LanguageService,
        private ngZone: NgZone,
    ) {
    }


    // Zeigt (schweren) Fehler (innerhalb der Angular-Zone) an
    // Bei schweren Fehlern sollte man `showReloadButton` auf `true` setzen.
    showErrorAlert(
        error: Error | string,
        showReloadButton: boolean = false,
        unknownErrorMessage: string = "Unknown Error"
    ) {
        this.ngZone.run(() => {

            // Fehlermeldung ermitteln
            let message: string
            if (typeof error === "string") {
                message = error
            } else {
                message = error?.message
            }
            if (!!message) {
                message = this.languageService.get(message).value
            } else {
                message = unknownErrorMessage
            }

            // Buttons
            const buttons: AlertButton[] = [
                {
                    text: this.languageService.get(_("services.notification_service.error_message.close_button")).value,
                    role: "cancel",
                },
            ]
            if (showReloadButton) {
                buttons.unshift({
                    text: this.languageService.get(_("services.notification_service.error_message.reload_button")).value,
                    role: "reload",
                    handler: () => {
                        window.location.reload()
                    }
                })
            }

            this.alertController.create({
                header: this.languageService.get(_("services.notification_service.error_message.header")).value,
                // subHeader: "",
                message,
                cssClass: "error-message-alert",
                // inputs?: AlertInput[];
                buttons,
                // backdropDismiss?: boolean; (Standard: true)
                // htmlAttributes?: AlertAttributes;
                // keyboardClose?: boolean; (Standard: true)
                // id?: string;
            }).then((alert) => {
                alert.present()
            })

        })
    }


    // Zeigt Fehler (innerhalb der Angular-Zone) an
    showErrorMessage(
        error: Error | string,
        duration: number = 10000,
        unknownErrorMessage: string = "Unknown Error"
    ) {
        this.ngZone.run(() => {

            // Fehlermeldung ermitteln
            let message: string
            if (typeof error === "string") {
                message = error
            } else {
                message = error?.message
            }
            if (!!message) {
                message = this.languageService.get(message).value
            } else {
                message = unknownErrorMessage
            }

            // Buttons
            const buttons: ToastButton[] = [
                {
                    icon: "close",
                    text: " ",
                    role: 'cancel',
                },
            ]

            this.toastController.create({
                // header: `${timeStr} - An error occurred`,
                message,
                cssClass: "error-message-toast",
                duration,
                buttons,
                position: "bottom",
                // translucent?: boolean;
                // animated?: boolean;
                icon: "alert-circle-outline",
                // htmlAttributes?: ToastAttributes;
                color: "danger",  // "primary", "secondary", "tertiary", "success", "warning", "danger", "light", "medium", "dark"
                // mode?: Mode;
                // keyboardClose: false,
                // id?: string;
                // enterAnimation?: AnimationBuilder;
                // leaveAnimation?: AnimationBuilder;
            }).then((toast) => {
                toast.present()
            })

        })
    }


    // Zeigt Warnungen (innerhalb der Angular-Zone) an
    showWarningMessage(
        warning: string | Error,
        duration: number = 8000,
        unknownWarningMessage: string = "Unknown Warning"
    ) {
        this.ngZone.run(() => {

            // Fehlermeldung ermitteln
            let message: string
            if (typeof warning === "string") {
                message = warning
            } else {
                message = warning?.message
            }
            if (!!message) {
                message = this.languageService.get(message).value
            } else {
                message = unknownWarningMessage
            }

            // Buttons
            const buttons: ToastButton[] = [
                {
                    icon: "close",
                    text: " ",
                    role: 'cancel',
                },
            ]

            this.toastController.create({
                // header: `${timeStr} - An error occurred`,
                message,
                cssClass: "warning-message-toast",
                duration,
                buttons,
                position: "bottom",
                // translucent?: boolean;
                // animated?: boolean;
                icon: "flash-outline",
                // htmlAttributes?: ToastAttributes;
                color: "warning",  // "primary", "secondary", "tertiary", "success", "warning", "danger", "light", "medium", "dark"
                // mode?: Mode;
                // keyboardClose: false,
                // id?: string;
                // enterAnimation?: AnimationBuilder;
                // leaveAnimation?: AnimationBuilder;
            }).then((toast) => {
                toast.present()
            })

        })
    }


    // Zeigt wichtige Informationen (innerhalb der Angular-Zone) an
    showInfoMessage(
        info: string | Error,
        duration: number = 5000,
        unknownInfoMessage: string = "Unknown Info"
    ) {
        this.ngZone.run(() => {

            // Fehlermeldung ermitteln
            let message: string
            if (typeof info === "string") {
                message = info
            } else {
                message = info?.message
            }
            if (!!message) {
                message = this.languageService.get(message).value
            } else {
                message = unknownInfoMessage
            }

            // Buttons
            const buttons: ToastButton[] = [
                {
                    icon: "close",
                    text: " ",
                    role: 'cancel',
                },
            ]

            this.toastController.create({
                // header: `${timeStr} - An error occurred`,
                message,
                cssClass: "info-message-toast",
                duration,
                buttons,
                position: "bottom",
                // translucent?: boolean;
                // animated?: boolean;
                icon: "information-circle",
                // htmlAttributes?: ToastAttributes;
                // color: "secondary",  // "primary", "secondary", "tertiary", "success", "warning", "danger", "light", "medium", "dark"
                // mode?: Mode;
                // keyboardClose: false,
                // id?: string;
                // enterAnimation?: AnimationBuilder;
                // leaveAnimation?: AnimationBuilder;
            }).then((toast) => {
                toast.present()
            })

        })
    }


    // Zeigt wichtige Informationen (innerhalb der Angular-Zone) an
    showSuccessMessage(
        message: string,
        duration: number = 3000
    ) {
        this.ngZone.run(() => {

            // Mögliche Fehlermeldung ermitteln
            message = this.languageService.get(message).value

            // Buttons
            const buttons: ToastButton[] = [
                {
                    icon: "close",
                    role: 'cancel',
                },
            ]

            this.toastController.create({
                // header: `${timeStr} - An error occurred`,
                message,
                cssClass: "success-message-toast",
                duration,
                buttons,
                position: "bottom",
                // translucent?: boolean;
                // animated?: boolean;
                icon: "checkmark-circle-outline",
                // htmlAttributes?: ToastAttributes;
                color: "success",  // "primary", "secondary", "tertiary", "success", "warning", "danger", "light", "medium", "dark"
                // mode?: Mode;
                // keyboardClose: false,
                // id?: string;
                // enterAnimation?: AnimationBuilder;
                // leaveAnimation?: AnimationBuilder;
            }).then((toast) => {
                toast.present()
            })

        })
    }


}




