export const environment = {
    appname: "aTimeRecording",
    production: true,
    serviceWorker: true,
    build: "prod",
    version: "1.4.19",
    keycloak: {
        issuer: "https://keycloak.activity-time-recording.com/",
        realm: "timetracking",
        clientId: "timetracking.pwa"
    },
    api: {
        baseUrl: "https://api.activity-time-recording.com",
    },
    glitchTip: {
        dsn: "https://c8f5363a52244128bf774104fa493e93@glitchtip.activity-time-recording.com/3"
    },
}
