import {Injectable} from "@angular/core"
import {Network} from "@capacitor/network"
import {BehaviorSubject} from "rxjs"


@Injectable({
    providedIn: 'root'
})
export class NetworkService {

    networkConnectedSubject = new BehaviorSubject<boolean>(false)


    constructor() {
        this.initService()  // Asynchron
    }


    async initService() {
        console.debug("NetworkService.initService()")

        if ((await Network.getStatus()).connected) {
            this.networkConnectedSubject.next(true)
        }

        Network.addListener("networkStatusChange", (status) => {
            if (status.connected !== this.networkConnectedSubject.value) {
                this.networkConnectedSubject.next(status.connected)
            }
        })

    }

}
