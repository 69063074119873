import {Injectable} from '@angular/core'
import {Storage} from "@ionic/storage-angular"
import {KeycloakService} from "../services/keycloak.service"
import {environment} from "../../environments/environment"
import {Drivers} from "@ionic/storage"
import {ApiSocketService} from "../services/api.socket.service"
import {AutostopRecordings, DefaultTimespan, SortOrder} from "../app.constants"
import {NetworkService} from "../services/network.service"
import {NotificationService} from "../services/notification.service"


enum Key {
    HIDE_HELP_CARD = "hideHelpCard.",  // wird mit dem Namen der Seite ergänzt
    AUTOSTOP_RECORDINGS = "autostopRecordings",
    EXPORT_DEFAULT_TIMESPAN = "exportDefaultTimespan",
    EXPORT_DEFAULT_FORMAT = "exportDefaultFormat",
    PROJECTS_PAGE_SORT_ORDER = "projectsPageSortOrder",
    REPORTS_PAGE_SORT_ORDER = "reportsPageSortOrder",
    ACTIVITIES_PAGE_SORT_ORDER = "activitiesPageSortOrder",
    PROJECT_PROPOSALS_LIST = "projectProposalsList",
    NAME_PROPOSALS_LIST = "nameProposalsList.",  // wird mit Projekt-UUID ergänzt
    DURATION_PROPOSALS_LIST = "durationProposalsList",
    TAGS_PAGE_SORT_ORDER = "tagsPageSortOrder",
    PAGE_COLOR = "pageColor.",  // wird mit dem Namen der Seite ergänzt
}


interface LocalSetting {
    value: any
    // locallyChanged?: boolean  // Wenn True, dann muss diese Einstellung beim Synchronisieren zum Server übermittelt werden.
}


@Injectable({
    providedIn: 'root'
})
export class GlobalUserSettingsService {

    private _globalUserSettingsStores = new Map<string, Storage>()
    private userUuid: string
    private changedKeys: Set<string> = new Set()  // Kürzlich geänderte Einstellungen


    constructor(
        private keycloakService: KeycloakService,
        private apiSocketService: ApiSocketService,
        private networkService: NetworkService,
        private notificationService: NotificationService,
    ) {

        // Auf Änderungen der User-UUID horchen
        this.keycloakService.userUuidSubject.subscribe((userUuid) => {
            this.userUuid = userUuid

            if (this.userUuid) {
                // Sobald online -> alle Einstellungen synchronisieren
                this.networkService.networkConnectedSubject.subscribe(async () => {
                    // Synchronisieren
                    await this.sync()
                })
            }

        })

        // Auf Änderung einer Einstellung horchen
        this.apiSocketService.settingUpdated.subscribe(async (key) => {

            // Updates von selbst geänderten Einstellung ignorieren
            if (this.changedKeys.has(key)) {
                this.changedKeys.delete(key)
            } else {
                // Einstellung vom Server laden und lokal speichern
                const value = (await this.apiSocketService.getSettings([key]))[key]
                await this.setLocalSetting(key, {value})
            }

        })
    }


    private async getGlobalUserSettingsStore() {
        let dbName: string
        if (this.userUuid) {
            dbName = `${environment.appname}_${this.userUuid}`
        } else {
            dbName = environment.appname
        }
        if (!this._globalUserSettingsStores.has(dbName)) {
            const store = new Storage({
                name: dbName,
                storeName: "globalUserSettings",
                driverOrder: [Drivers.IndexedDB]
            })
            this._globalUserSettingsStores.set(dbName, await store.create())
        }
        return this._globalUserSettingsStores.get(dbName)
    }


    private async getLocalSetting(key: Key | string): Promise<LocalSetting> {
        const store = await this.getGlobalUserSettingsStore()
        return await store.get(key) as LocalSetting
    }


    private async setLocalSetting(key: Key | string, localSetting: LocalSetting) {
        const store = await this.getGlobalUserSettingsStore()
        return await store.set(key, localSetting)
    }


    private async removeLocalSetting(key: Key | string) {
        const store = await this.getGlobalUserSettingsStore()
        return await store.remove(key)
    }


    private async clearLocalStore() {
        const store = await this.getGlobalUserSettingsStore()
        return await store.clear()
    }


    private async getValue<T>(key: Key | string): Promise<T> {
        let localSetting = await this.getLocalSetting(key)
        if (localSetting === null) {
            // Einstellung vom Server laden und lokal speichern
            const value: T = (await this.apiSocketService.getSettings([key]))[key]
            await this.setLocalSetting(key, {value})
            return value
        } else {
            return localSetting.value
        }
    }


    private async setValue(key: Key | string, value: any) {
        try {

            // Lokal speichern und Key als geändert markieren
            await this.setLocalSetting(key, {value})
            this.changedKeys.add(key)

            // Global speichern wenn online
            if (this.networkService.networkConnectedSubject.value) {
                const keysValues = {}
                keysValues[key] = value
                await this.apiSocketService.setSettings(keysValues)
            }

        } catch (error) {
            this.notificationService.showWarningMessage(error)
        }
    }


    // Holt alle Einstellungen vom Server und schreibt sie in die lokale DB
    private async sync() {
        console.debug("GlobalUserSettingsService.sync()")

        // Updates von lokal geänderten Einstellung hochladen
        if (this.changedKeys.size > 0) {
            const keysValues = {}
            for (let changedKey of this.changedKeys) {
                // Lokale Einstellung laden und für Upload vorbereiten
                const localSetting = await this.getLocalSetting(changedKey)
                keysValues[changedKey] = localSetting.value
            }
            // Alle lokal geänderten Einstellungen zum Server übertragen
            await this.apiSocketService.setSettings(keysValues)
        }

        // Einstellungen synchronisieren
        const allSettings = await this.apiSocketService.getSettings(null)
        await this.clearLocalStore()
        this.changedKeys.clear()
        for (let [key, value] of Object.entries(allSettings)) {
            await this.setLocalSetting(key, {value})
        }
    }


    async getHideHelpCard(pageName: string): Promise<boolean> {
        console.assert(!!pageName, "pageName empty")
        const key = Key.HIDE_HELP_CARD + pageName
        const value = await this.getValue<boolean>(key)
        if (value === null) {
            return false
        } else {
            return value
        }
    }


    async setHideHelpCard(pageName: string, value: boolean) {
        console.assert(!!pageName, "pageName empty")
        const key = Key.HIDE_HELP_CARD + pageName
        return this.setValue(key, value)
    }


    async getAutostopRecordings(): Promise<AutostopRecordings> {
        const value = await this.getValue<AutostopRecordings>(Key.AUTOSTOP_RECORDINGS)
        if (value === null) {
            return AutostopRecordings.STOP
        } else if (typeof value === "boolean") {
            // Workaround für alte Einstellungen
            if (value) {
                return AutostopRecordings.STOP
            } else {
                return AutostopRecordings.NOT_STOP
            }
        } else {
            return value
        }
    }


    async setAutostopRecordings(value: AutostopRecordings) {
        return this.setValue(Key.AUTOSTOP_RECORDINGS, value)
    }


    async getExportDefaultTimespan(): Promise<DefaultTimespan> {
        return this.getValue<DefaultTimespan>(Key.EXPORT_DEFAULT_TIMESPAN)
    }


    async setExportDefaultTimespan(value: DefaultTimespan) {
        return this.setValue(Key.EXPORT_DEFAULT_TIMESPAN, value)
    }


    async getExportDefaultFormat(): Promise<string> {
        return this.getValue<string>(Key.EXPORT_DEFAULT_FORMAT)
    }


    async setExportDefaultFormat(value: string) {
        return this.setValue(Key.EXPORT_DEFAULT_FORMAT, value)
    }


    async getProjectsPageSortOrder(): Promise<{field, sortOrder}> {
        const value = await this.getValue<{field, sortOrder}>(Key.PROJECTS_PAGE_SORT_ORDER)
        if (value === null) {
            return {field: "ct", sortOrder: "dsc"}
        } else {
            return value
        }
    }


    async setProjectsPageSortOrder(value: {field, sortOrder}) {
        return this.setValue(Key.PROJECTS_PAGE_SORT_ORDER, value)
    }


    async getReportsPageSortOrder(): Promise<{field, sortOrder}> {
        const value = await this.getValue<{field, sortOrder}>(Key.REPORTS_PAGE_SORT_ORDER)
        if (value === null) {
            return {field: "ct", sortOrder: "dsc"}
        } else {
            return value
        }
    }


    async setReportsPageSortOrder(value: {field, sortOrder}) {
        return this.setValue(Key.REPORTS_PAGE_SORT_ORDER, value)
    }


    async getActivitiesPageSortOrder(): Promise<SortOrder> {
        const value = await this.getValue<SortOrder>(Key.ACTIVITIES_PAGE_SORT_ORDER)
        if (value === null) {
            return SortOrder.ASCENDING
        } else {
            return value
        }
    }


    async setActivitiesPageSortOrder(value: SortOrder) {
        return this.setValue(Key.ACTIVITIES_PAGE_SORT_ORDER, value)
    }


    async getProjectProposalsList(): Promise<string[]> {
        const value = await this.getValue<string[]>(Key.PROJECT_PROPOSALS_LIST)
        if (value === null) {
            return []
        } else {
            return value
        }
    }


    async setProjectProposalsList(value: string[]) {
        return this.setValue(Key.PROJECT_PROPOSALS_LIST, value)
    }


    async getNameProposalsList(projectUuid: string = "no_project"): Promise<string[]> {
        if (!projectUuid) {
            projectUuid = "no_project"
        }
        const key = Key.NAME_PROPOSALS_LIST + projectUuid
        const value = await this.getValue<string[]>(key)
        if (value === null) {
            return []
        } else {
            return value
        }
    }


    async setNameProposalsList(projectUuid: string = "no_project", value: string[]) {
        if (!projectUuid) {
            projectUuid = "no_project"
        }
        const key = Key.NAME_PROPOSALS_LIST + projectUuid
        return this.setValue(key, value)
    }


    async getDurationProposalsList(): Promise<string[]> {
        const value = await this.getValue<string[]>(Key.DURATION_PROPOSALS_LIST)
        if (value === null) {
            return ["30m", "1h", "1h 30m"]
        } else {
            return value
        }
    }


    async setDurationProposalsList(value: string[]) {
        return this.setValue(Key.DURATION_PROPOSALS_LIST, value)
    }


    async getTagsPageSortOrder(): Promise<{field, sortOrder}> {
        const value = await this.getValue<{field, sortOrder}>(Key.TAGS_PAGE_SORT_ORDER)
        if (value === null) {
            return {field: "ct", sortOrder: "dsc"}
        } else {
            return value
        }
    }


    async setTagsPageSortOrder(value: {field, sortOrder}) {
        return this.setValue(Key.TAGS_PAGE_SORT_ORDER, value)
    }


    async getPageColor(pageName: string): Promise<string> {

        // Farben der Unterseiten an Hauptseiten anpassen
        const subPages = new Map([
            ["edit-activity", "activities"],
            ["add-project", "projects"],
            ["edit-project", "projects"],
            ["add-report", "reports"],
            ["edit-report", "reports"],
            ["show-report", "reports"],
            ["add-tag", "tags"],
            ["edit-tag", "tags"],
        ])
        pageName = subPages.get(pageName) || pageName

        // Standard-Seitenfarben
        const defaultColors = new Map([
            ["activities", "secondary"],
            ["export", "primary"],
            ["fast-input", "tertiary"],
            ["projects", "dark"],
            ["recording", "success"],
            ["reports", "warning"],
            ["tags", "danger"],
        ])

        // Nicht angemeldet: Standardfarbe zurückgeben
        if (!this.userUuid) {
            return defaultColors.get(pageName) || "primary"
        }

        // Farbe aus DB lesen und zurück geben
        const key = Key.PAGE_COLOR + pageName
        const value = await this.getValue<string>(key)
        if (value === null) {
            return defaultColors.get(pageName) || "primary"
        } else {
            return value
        }

    }


    async setPageColor(pageName: string, color: string) {
        console.debug(`GlobalUserSettings.setPageColor(${pageName}, ${color})`)
        const key = Key.PAGE_COLOR + pageName
        return this.setValue(key, color)
    }


}
