import {environment} from "../../environments/environment"


// Liest einen Wert aus dem LocalStorage des Browsers
export function getLocalStorageValue<T>(key: string):T {
    const jsonValue = localStorage.getItem(`${environment.appname}_${key}`)
    if (!!jsonValue) {
        return JSON.parse(jsonValue)
    } else {
        return null
    }
}


// Schreibt einen Wert in den LocalStorage des Browsers
export function setLocalStorageValue<T>(key: string, value: T) {
    const jsonValue = JSON.stringify(value)
    localStorage.setItem(`${environment.appname}_${key}`, jsonValue)
}


// Löscht einen Eintrag aus dem LocalStorage des Browsers
export function removeLocalStorageItem(key: string) {
    localStorage.removeItem(`${environment.appname}_${key}`)
}
