import {environment} from "../../environments/environment"
import {DateTime} from "luxon"


// Liest einen Wert aus dem SessionStorage des Browsers.
// Abgelaufene Einträge werden gelöscht.
export function getSessionStorageValue<T>(key: string):T {
    const jsonValue = sessionStorage.getItem(`${environment.appname}_${key}`)
    if (!!jsonValue) {
        const data = JSON.parse(jsonValue)

        // TTL prüfen
        if (data.ttl !== null) {
            if (DateTime.fromISO(data.ttl) < DateTime.now()) {
                // TTL abgelaufen
                removeSessionStorageItem(key)
                return null
            }
        }
        // Fertig
        return data.value
    } else {
        // Fertig
        return null
    }
}


// Schreibt einen Wert in den SessionStorage des Browsers
export function setSessionStorageValue<T>(key: string, value: T, ttlSeconds: number = null) {
    // TTL
    let ttl: string = null
    if (ttlSeconds !== null) {
        ttl = DateTime.now().toUTC().plus({"seconds": ttlSeconds}).toISO()
    }
    // Speichern
    const jsonValue = JSON.stringify({ttl, value})
    sessionStorage.setItem(`${environment.appname}_${key}`, jsonValue)
}


// Löscht einen Eintrag aus dem SessionStorage des Browsers
export function removeSessionStorageItem(key: string) {
    sessionStorage.removeItem(`${environment.appname}_${key}`)
}


// Löscht den kompletten SessionStorage
export function clearSessionStorage() {
    sessionStorage.clear()
}

