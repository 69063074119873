import {NgModule, Provider, Type, ModuleWithProviders, Injectable, ErrorHandler} from '@angular/core'
import {BrowserModule} from '@angular/platform-browser'
import {RouteReuseStrategy} from '@angular/router'
import {IonicModule, IonicRouteStrategy, Platform} from '@ionic/angular'
import {FormsModule} from '@angular/forms'
import {AppComponent} from './app.component'
import {AppRoutingModule} from './app-routing.module'
import {TranslateLoader, TranslateModule} from '@ngx-translate/core'
import {TranslateHttpLoader} from '@ngx-translate/http-loader'
import {HttpClient, HttpClientModule} from "@angular/common/http"
import {environment} from "../environments/environment"
import {BrowserAnimationsModule} from '@angular/platform-browser/animations'
import {ServiceWorkerModule} from '@angular/service-worker'
import * as Sentry from "@sentry/browser"
import {localSystemSettings} from "./storage/local-system-settings"
import {getCompileTime, getDeploymentInfo} from "./tools/app.tools"


// Sentry aktivieren, wenn erlaubt
Sentry.init({
    enabled: environment.production && localSystemSettings.getSentryEnabled(),
    dsn: environment.glitchTip.dsn,
    release: environment.version,
    environment: environment.build,
    autoSessionTracking: false,
    attachStacktrace: true,
    maxValueLength: 2000,
    maxBreadcrumbs: 20,
})


@Injectable()
export class SentryErrorHandler implements ErrorHandler {

    constructor(
        private platform: Platform
    ) {
    }


    handleError(error) {
        console.error(error)

        // Fehler abfangen, die nicht übermittelt werden müssen
        const errorString = String(error)
        if (errorString.includes("ChunkLoadError")) {
            return
        } else if (
            errorString.includes("WebSocket connection to") &&
            errorString.includes("failed")
        ) {
            return
        } else if (errorString.includes("Connection to the server could not be established")) {
            return
        } else if (errorString.includes("Not logged in")) {
            return
        }

        const platforms = this.platform.platforms() || null
        if (!!platforms) {
            Sentry.setTag("platform", platforms.join(", "))
        }
        Sentry.setTag("deployment", getDeploymentInfo())
        Sentry.setTag("compiletime", getCompileTime().toISOString())
        Sentry.captureException(error.originalError || error)
    }
}


// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
    return new TranslateHttpLoader(http)
}


const providers: Provider[] = [
    {
        provide: RouteReuseStrategy,
        useClass: IonicRouteStrategy
    },
    {provide: ErrorHandler, useClass: SentryErrorHandler},
]


const moduleImports: Array<Type<any> | ModuleWithProviders<{}> | any[]> = [
    BrowserModule,
    HttpClientModule,
    IonicModule.forRoot({
        swipeBackEnabled: false,
    }),
    AppRoutingModule,
    TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [HttpClient]
        }
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
        enabled: environment.serviceWorker,
        // Register the ServiceWorker as soon as the app is stable
        // or after 10 seconds (whichever comes first).
        registrationStrategy: 'registerWhenStable:10000'
    }),
    FormsModule,
    BrowserAnimationsModule,
]


@NgModule({
    declarations: [AppComponent],
    imports: moduleImports,
    providers,
    bootstrap: [AppComponent]
})
export class AppModule {
}
