import {getLocalStorageValue, setLocalStorageValue} from "../tools/local-storage.tools"


enum Key {
    SENTRY_ENABLED = "sentryEnabled",
    PREFERRED_LANGUAGE = "preferredLanguage"
}


// Hier stehen nur die Einstellungen, die sofort bei Programmstart zur Verfügung
// stehen müssen. Es wird nicht nach angemeldetem Benutzer unterschieden.
// Andere lokale Einstellungen, sollten im "LocalUserSettingsService" gespeichert werden.
export namespace localSystemSettings {


    export function getSentryEnabled(): boolean {
        let value = getLocalStorageValue<boolean>(Key.SENTRY_ENABLED)
        if (value === null) {
            value = true
        }
        return value
    }


    export function setSentryEnabled(value: boolean) {
        setLocalStorageValue<boolean>(Key.SENTRY_ENABLED, value)
    }


    export function getPreferredLanguage(): string {
        return getLocalStorageValue<string>(Key.PREFERRED_LANGUAGE)
    }


    export function setPreferredLanguage(value: string) {
        setLocalStorageValue<string>(Key.PREFERRED_LANGUAGE, value)
    }

}

