import {Component, OnDestroy, OnInit} from '@angular/core'
import {PwaService} from "./services/pwa.service"
import {Subscription} from "rxjs"
import {environment} from "../environments/environment"
import {LanguageService} from "./services/language.service"
import {NavigationService} from "./services/navigation.service"
import {NetworkService} from "./services/network.service"
import {ApiSocketService} from "./services/api.socket.service"
import {KeycloakService} from "./services/keycloak.service"
import {CurrentUserService} from "./services/current-user.service"
import {GlobalUserSettingsService} from "./storage/global-user-settings.service"
import {ActivityService} from "./services/activity.service"


@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {

    APPNAME = environment.appname
    readonly PAGE_COLOR_RECORDING$ = this.globalUserSettingsService.getPageColor("recording")
    readonly PAGE_COLOR_FAST_INPUT$ = this.globalUserSettingsService.getPageColor("fast-input")
    readonly PAGE_COLOR_ACTIVITIES$ = this.globalUserSettingsService.getPageColor("activities")
    readonly PAGE_COLOR_PROJECTS$ = this.globalUserSettingsService.getPageColor("projects")
    readonly PAGE_COLOR_TAGS$ = this.globalUserSettingsService.getPageColor("tags")
    readonly PAGE_COLOR_REPORTS$ = this.globalUserSettingsService.getPageColor("reports")
    readonly PAGE_COLOR_EXPORT$ = this.globalUserSettingsService.getPageColor("export")

    loggedIn$ = this.keycloakService.loggedInSubject
    freeUser$ = this.keycloakService.freeUserGroupSubject
    basicUser$ = this.keycloakService.basicUserGroupSubject
    proUser$ = this.keycloakService.proUserGroupSubject
    fullName$ = this.currentUserService.fullNameSubject
    isAndroid = environment.build === "android"

    installEnabled = this.pwaService.installEnabled

    languages = this.languageService.languages
    private preferredLanguageSubscription: Subscription
    preferredLangId: string
    browserLanguageFlag = this.languageService.browserLanguageFlag
    private externalLanguageChange = false
    isStartpage = this.navigationService.isStartpage
    private keycloakUserUpdatedSubscription: Subscription
    private keycloakUserPlanChangedSubscription: Subscription
    private keycloakUserDeletedSubscription: Subscription
    private urlOpenListener
    private showGlobalErrorMessageSubscription: Subscription
    recording: boolean
    private recordingActivitiesSubscription: Subscription


    constructor(
        private networkService: NetworkService,
        private keycloakService: KeycloakService,
        private pwaService: PwaService,
        private languageService: LanguageService,
        private navigationService: NavigationService,
        private apiService: ApiSocketService,
        private currentUserService: CurrentUserService,
        private globalUserSettingsService: GlobalUserSettingsService,
        private activityService: ActivityService,
    ) {
    }


    async ngOnInit() {

        // Aktuelle Sprache überwachen
        this.preferredLanguageSubscription = this.languageService.currentPreferredLanguage.subscribe((preferredLanguageId) => {
            this.externalLanguageChange = true
            this.preferredLangId = preferredLanguageId
            setTimeout(() => {
                this.externalLanguageChange = false
            }, 100)
        })

        // Keycloak Benutzeränderungen überwachen
        this.keycloakUserUpdatedSubscription = this.apiService.keycloakUserUpdatedSubject.subscribe(async () => {
            // Programm neu laden
            location.href = "/"
        })

        // Keycloak Planänderungen überwachen
        this.keycloakUserPlanChangedSubscription = this.apiService.keycloakUserPlanChanged.subscribe(async () => {
            // Programm neu laden
            location.reload()
        })

        // Keycloak Benutzerlöschung überwachen
        this.keycloakUserDeletedSubscription = this.apiService.keycloakUserDeleted.subscribe(async () => {
            // Programm neu laden
            location.href = "/"
        })

        // Aktive Aufzeichnung prüfen
        if (this.loggedIn$.value) {
            this.recordingActivitiesSubscription = this.activityService.getRecordingActivitiesSubject().subscribe(
                (activities) => {
                    this.recording = activities?.length > 0
                }
            )
        }

        // // URL-Open abfangen (Universal Links on iOS and App Links on Android)
        // console.log("ABABABABABA")
        // this.urlOpenListener = await App.addListener("appUrlOpen", ({url}) => {
        //
        //     console.log("App opened with URL:", url)
        //
        //     if (url.startsWith("atr://logged-in")) {
        //         console.log("DDDDDDD")
        //
        //         // const parsedUrl = new URL(url)
        //         // const params = {}
        //         // parsedUrl.searchParams.forEach((value, key) => {
        //         //     params[key] = value
        //         // })
        //         //
        //         // console.log({hash: parsedUrl.hash, searchParams: params})
        //
        //         console.log("Sliced URL:", url.slice(5))
        //         location.replace(url.slice(5))
        //
        //         // this.router.navigate(["/logged-in"], {
        //         //     fragment: parsedUrl.hash,
        //         //     queryParams: params,
        //         // })
        //     }
        //
        //
        // })
        // console.log("BBBBBBBBBBBBBB")


    }


    ngOnDestroy() {
        if (this.preferredLanguageSubscription) {
            this.preferredLanguageSubscription.unsubscribe()
        }
        if (this.keycloakUserUpdatedSubscription) {
            this.keycloakUserUpdatedSubscription.unsubscribe()
        }
        if (this.keycloakUserPlanChangedSubscription) {
            this.keycloakUserPlanChangedSubscription.unsubscribe()
        }
        if (this.keycloakUserDeletedSubscription) {
            this.keycloakUserDeletedSubscription.unsubscribe()
        }
        if (this.urlOpenListener) {
            this.urlOpenListener.remove()
        }
        if (this.showGlobalErrorMessageSubscription) {
            this.showGlobalErrorMessageSubscription.unsubscribe()
        }
        if (this.recordingActivitiesSubscription) {
            this.recordingActivitiesSubscription.unsubscribe()
        }
    }


    async logout() {
        await this.keycloakService.logoutDirect()
    }


    async showInstallDialog() {
        await this.pwaService.showInstallDialog()
    }


    async onLanguageSelected() {
        if (this.externalLanguageChange) {
            return
        }
        console.debug(`AppComponent.onLanguageSelected(${this.preferredLangId})`)
        await this.languageService.onLanguageSelected(this.preferredLangId)
    }


}
