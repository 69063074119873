import {Injectable} from '@angular/core'
import {environment} from "../../environments/environment"
import {BehaviorSubject} from "rxjs"
import {Platform} from "@ionic/angular"
import {HttpClient, HttpParams} from "@angular/common/http"
import {parseJwt} from "../tools/auth.tools"
import {DateTime} from "luxon"
import {Router} from "@angular/router"
import {marker as _} from '@biesbjerg/ngx-translate-extract-marker'
import {setLocalStorageValue, getLocalStorageValue, removeLocalStorageItem} from "../tools/local-storage.tools"
import {clearSessionStorage} from "../tools/session-storage.tools"


// Keycloak-Fehlermeldungen für die Übersetzung markieren
_("errors.Invalid user credentials")


@Injectable({
    providedIn: 'root'
})
export class KeycloakService {

    // Token-Infos
    private accessToken: string
    private refreshToken: string
    private idToken: string
    private creationDateTime: DateTime
    private expiresDateTime: DateTime
    private refreshExpiresDateTime: DateTime

    // Daten aus dem Access-Token
    private parsedAccessToken
    private roles: Set<string>
    private userUuid: string

    private _loggedInSubject = new BehaviorSubject<boolean>(false)
    loggedInSubject = new BehaviorSubject<boolean>(false)

    userUuidSubject = new BehaviorSubject<string>("")
    freeUserGroupSubject = new BehaviorSubject<boolean>(false)
    basicUserGroupSubject = new BehaviorSubject<boolean>(false)
    proUserGroupSubject = new BehaviorSubject<boolean>(false)


    constructor(
        private platform: Platform,
        private httpClient: HttpClient,
        private router: Router,
    ) {
        console.debug("KeycloakService.constructor()")

        // Init
        this.init()  // asynchron
    }


    async init() {
        console.debug("KeycloakService.init()")

        // Token-Infos aus localStorage auslesen
        this.localStorageReadTokenInfos()

        // Alten Token verwenden, wenn möglich
        if (this.accessToken && this.expiresDateTime > DateTime.now().toUTC()) {
            // Token ist noch gültig

            // Daten aus dem Access-Token holen
            this.processAccessToken()

            // Logged in
            console.debug("Old token is valid.")
            this._loggedInSubject.next(true)
        } else if (this.refreshToken && this.refreshExpiresDateTime > DateTime.now().toUTC()) {
            // Token neu einlesen
            await this.updateToken(0, true)
        }

        // Wird immer ausgeführt, wenn ein- oder ausgeloggt wird
        this._loggedInSubject.subscribe((loggedIn) => {
            console.debug(`KeycloakService._loggedInSubject(loggedIn:${loggedIn})`)

            // Beim An- und Abmelden wird der SessionStorage-Cache gelöscht
            clearSessionStorage()

            if (loggedIn) {

                this.userUuidSubject.next(this.userUuid)

                if (this.roles.has("Pro-User-Role")) {
                    // Pro-User
                    this.proUserGroupSubject.next(true)
                    this.basicUserGroupSubject.next(false)
                    this.freeUserGroupSubject.next(false)
                } else if (this.roles.has("Basic-User-Role")) {
                    // Basic-User
                    this.proUserGroupSubject.next(false)
                    this.basicUserGroupSubject.next(true)
                    this.freeUserGroupSubject.next(false)
                } else {
                    // Free-User (Eingeloggte Benutzer sind mindestens Free-User)
                    this.proUserGroupSubject.next(false)
                    this.basicUserGroupSubject.next(false)
                    this.freeUserGroupSubject.next(true)
                }

                // Log
                console.info("Log in finished")

            } else {

                // Tokens löschen
                this.localStorageRemoveTokenInfos()

                // Entladen
                this.userUuidSubject.next("")
                this.freeUserGroupSubject.next(false)
                this.basicUserGroupSubject.next(false)
                this.proUserGroupSubject.next(false)

                // Log
                console.info("Log out finished")

            }

            // Ab jetzt kann losgelegt werden --> Public Event auslösen
            this.loggedInSubject.next(loggedIn)

        })
    }


    private onAuthError(errorData) {
        console.debug("KeycloakService.onAuthError()")

        // Es ist ein Fehler aufgetreten
        console.error([errorData.error, errorData.error_description])

        // Login zurücksetzen
        this._loggedInSubject.next(false)
    }


    processTokenResponse(tokenResponse: object) {
        // Rohinformationen
        this.accessToken = tokenResponse["access_token"]
        this.refreshToken = tokenResponse["refresh_token"]
        this.idToken = tokenResponse["id_token"]
        this.creationDateTime = DateTime.now().toUTC()
        this.expiresDateTime = this.creationDateTime.plus({seconds: tokenResponse["expires_in"]})
        this.refreshExpiresDateTime = this.creationDateTime.plus({seconds: tokenResponse["refresh_expires_in"]})

        // Token-Infos in localStorage speichern
        this.localStorageWriteTokenInfos()

        // Informationen aus dem Access-Token
        this.processAccessToken()

        // Authorisierung bestätigen
        this._loggedInSubject.next(true)
    }


    processAccessToken() {
        // Informationen aus dem Access-Token
        this.parsedAccessToken = parseJwt(this.accessToken)
        this.roles = new Set(this.parsedAccessToken.realm_access.roles)
        this.userUuid = this.parsedAccessToken.sub
    }


    // Login mit Benutzername (E-Mail) und Passwort
    // Keycloak: Direct Access Grants Enabled
    async loginDirect(username: string, password: string, nextPage: string = null) {
        console.debug("KeycloakService.loginDirect()")

        const body = new HttpParams()  // Achtung HttpParams ist immutable
            .set("grant_type", "password")
            .set("client_id", environment.keycloak.clientId)
            .set("username", username)
            .set("password", password)
            .set("scope", "openid")  // damit man den id_token bekommt
        const url = `${environment.keycloak.issuer}realms/${environment.keycloak.realm}/protocol/openid-connect/token`

        try {
            // Token-Request
            const tokenResponse = await this.httpClient.post(url, body).toPromise()

            // Token-Response verarbeiten
            this.processTokenResponse(tokenResponse)

            // Weiterleiten
            let redirectUrl = "/logged-in"
            if (nextPage) {
                redirectUrl += "/" + nextPage
            }
            await this.router.navigateByUrl(redirectUrl)
        } catch (e) {
            const errorData = e.error
            this.onAuthError(errorData)
            throw new Error(errorData.error_description)
        }

    }


    async logoutDirect() {
        console.debug("KeycloakService.logoutDirect()")

        // Ausloggen
        const body = new HttpParams()  // Achtung HttpParams ist immutable
            .set("client_id", environment.keycloak.clientId)
            .set("refresh_token", this.refreshToken)
        const url = `${environment.keycloak.issuer}realms/${environment.keycloak.realm}/protocol/openid-connect/logout`

        // Logout-Request
        try {
            await this.httpClient.post(url, body).toPromise()
        } catch (e) {
        }

        // Tokens aus localStorage löschen
        this.localStorageRemoveTokenInfos()

        // Variablen leeren
        this.accessToken = null
        this.refreshToken = null
        this.idToken = null
        this.creationDateTime = null
        this.expiresDateTime = null
        this.refreshExpiresDateTime = null
        this.parsedAccessToken = null
        this.roles = null
        this.userUuid = null

        // Log out
        this._loggedInSubject.next(false)

        // Zur Startseite weiterleiten
        await this.router.navigateByUrl("/start")
    }


    async getToken(withTokenUpdate = true) {
        // Wenn Token abgelaufen, dann neu holen
        if (withTokenUpdate) {
            await this.updateToken()
        }
        // Token zurückgeben
        return this.accessToken
    }


    // Prüft ob der Token noch mindestens drei Stunden lang gültig ist.
    isTokenActive(minValiditySeconds: number = 10800) {
        return (
            this.expiresDateTime &&
            this.expiresDateTime > DateTime.now().toUTC().plus({seconds: minValiditySeconds})
        )
    }


    // Wenn der Token nicht mehr mindestens einen Tag lang gültig ist, wird dieser erneuert.
    async updateToken(minValiditySeconds: number = 86400, forced = false) {
        if (this.refreshToken && (forced || this.expiresDateTime < DateTime.now().toUTC().plus({seconds: minValiditySeconds}))) {
            console.debug("KeycloakService.updateToken(<updating>)")

            const body = new HttpParams()  // Achtung HttpParams ist immutable
                .set("grant_type", "refresh_token")
                .set("client_id", environment.keycloak.clientId)
                .set("refresh_token", this.refreshToken)
                .set("scope", "openid")  // damit man den id_token bekommt
            const url = `${environment.keycloak.issuer}realms/${environment.keycloak.realm}/protocol/openid-connect/token`

            try {
                // Token-Request
                const tokenResponse = await this.httpClient.post(url, body).toPromise()
                // Token-Response verarbeiten
                this.processTokenResponse(tokenResponse)
            } catch (e) {
                const errorData = e.error
                this.onAuthError(errorData)
            }

        }
    }


    // Token-Infos löschen
    localStorageRemoveTokenInfos() {
        removeLocalStorageItem("accessToken")
        removeLocalStorageItem("refreshToken")
        removeLocalStorageItem("idToken")
        removeLocalStorageItem("creationIso")
        removeLocalStorageItem("tokenExpiresIso")
        removeLocalStorageItem("refreshExpiresIso")
    }


    // Token-Infos in localStorage schreiben
    localStorageWriteTokenInfos() {
        setLocalStorageValue("accessToken", this.accessToken)
        setLocalStorageValue("accessToken", this.accessToken)
        setLocalStorageValue("refreshToken", this.refreshToken)
        setLocalStorageValue("idToken", this.idToken)
        setLocalStorageValue("creationIso", this.creationDateTime.toISO())
        setLocalStorageValue("tokenExpiresIso", this.expiresDateTime.toISO())
        setLocalStorageValue("refreshExpiresIso", this.refreshExpiresDateTime.toISO())
    }


    // Token-Infos aus localStorage auslesen
    localStorageReadTokenInfos() {
        this.accessToken = getLocalStorageValue("accessToken")
        this.refreshToken = getLocalStorageValue("refreshToken")
        this.idToken = getLocalStorageValue("idToken")
        this.creationDateTime = DateTime.fromISO(getLocalStorageValue("creationIso"))
        this.expiresDateTime = DateTime.fromISO(getLocalStorageValue("tokenExpiresIso"))
        this.refreshExpiresDateTime = DateTime.fromISO(getLocalStorageValue("refreshExpiresIso"))
    }


}
