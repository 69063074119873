import {Injectable} from '@angular/core'
import {Socket, io} from "socket.io-client"
import {environment} from "../../environments/environment"
import {
    SetPasswordParams, SocketResponse, StripeCheckoutSession, UpdateUserParams,
    User, StripeCustomerPortalSession,
    Project, CreateProjectParams, UpdateProjectParams,
    Activity, CreateActivityParams, UpdateActivityParams,
    Report, CreateReportParams, UpdateReportParams, CreateTagParams, Tag, UpdateTagParams,
} from "../app.interfaces"
import {Subject} from "rxjs"
import {
    StripePlan,
    EVENT_KEYCLOAK_USER_UPDATED,
    EVENT_KEYCLOAK_USER_DELETED,
    EVENT_KEYCLOAK_USER_PLAN_CHANGED,
    EVENT_USER_UPDATED,
    EVENT_PROJECT_UPDATED,
    EVENT_REPORT_UPDATED,
    EVENT_ACTIVITY_UPDATED,
    EVENT_SETTING_UPDATED,
    PLATFORM_NAMES_SHORTCUTS,
    EVENT_TAG_UPDATED,
    EVENT_ROOM_JOINED
} from "../app.constants"
import {marker as _} from '@biesbjerg/ngx-translate-extract-marker'
import {DateTime} from "luxon"
import {KeycloakService} from "./keycloak.service"
import {Platform} from "@ionic/angular"
import {getDeploymentInfo} from "../tools/app.tools"
import AwaitLock from "await-lock"
import {NetworkService} from "./network.service"
import {NotificationService} from "./notification.service"


// Api-Service Fehlermeldungen für die Übersetzung markieren
_("errors.http_response.user_not_found")
_("errors.http_response.user_already_exists")
_("errors.http_response.user_already_deleted")
_("errors.http_response.internal_server_error")
_("errors.http_response.project_already_exists")
_("errors.http_response.project_not_found")
_("errors.http_response.project_already_deleted")
_("errors.http_response.project_name_already_exists")
_("errors.http_response.activity_not_found")
_("errors.http_response.activity_already_deleted")
_("errors.http_response.report_already_exists")
_("errors.http_response.report_not_found")
_("errors.http_response.report_already_deleted")
_("errors.http_response.report_name_already_exists")
_("errors.http_response.tag_already_exists")
_("errors.http_response.tag_not_found")
_("errors.http_response.tag_already_deleted")
_("errors.http_response.tag_name_already_exists")


@Injectable({
    providedIn: 'root'
})
export class ApiSocketService {

    private socket: Socket = null
    private _authenticated = false
    private authenticatingLock = new AwaitLock()
    private readonly platforms: string[]
    private readonly build: string
    private readonly version: string
    private readonly deployment: string

    // User-Events
    userUpdatedSubject = new Subject<void>()
    keycloakUserUpdatedSubject = new Subject<void>()
    keycloakUserPlanChanged = new Subject<void>()
    keycloakUserDeleted = new Subject<void>()
    projectUpdated = new Subject<void>()
    reportUpdated = new Subject<void>()
    activityUpdated = new Subject<string>()  // startDatetimeIso als Parameter
    settingUpdated = new Subject<string>()  // Key der Einstellung als Parameter
    tagUpdated = new Subject<void>()


    constructor(
        private keycloakService: KeycloakService,
        private platform: Platform,
        private networkService: NetworkService,
        private notificationService: NotificationService,
    ) {
        console.debug("ApiSocketService.constructor()")

        // Plattform-Shortcuts und Build ermitteln
        const platforms = []
        for (let platform of new Set([...this.platform.platforms(),])) {
            const shortcut = PLATFORM_NAMES_SHORTCUTS[platform]
            if (!!shortcut) {
                platforms.push(shortcut)
            } else {
                platforms.push(platform)
            }
        }
        platforms.sort()
        this.platforms = platforms
        this.build = environment.build
        this.version = environment.version
        this.deployment = getDeploymentInfo()

        // Socket.IO initialisieren und Verbindungsaufbau starten
        this.socket = io(
            environment.api.baseUrl, {
                rememberUpgrade: true,  // https://socket.io/docs/v4/client-options/#rememberupgrade
                autoConnect: false,  // https://socket.io/docs/v4/client-options/#autoconnect
            }
        )

        // Netzwerkverbindung überwachen
        this.networkService.networkConnectedSubject.subscribe((networkConnected) => {
            if (networkConnected) {
                if (!this.socket.connected) {
                    this.socket.connect()
                }
            } else {
                if (this.socket.connected) {
                    this.socket.disconnect()
                }
            }
        })

        // Automatisches Übermitteln des Tokens alle 30 min. starten.
        setInterval(async () => {
            console.debug("ApiSocketService.checkTokenTimer()")

            // Nur wenn verbunden und authentifiziert
            if (!this.socket.connected) {
                return
            }
            if (!this.authenticated) {
                return
            }

            //  Neu authentifizieren
            await this.authenticate()
        }, 30 * 60 * 1000)


        // On Connected
        this.socket.on("connect", () => {
            console.debug(`ApiSocketService.on(CONNECT)`)

            if (this.authenticated) {
                // Neu authentifizieren, denn der Server weiss nichts mehr von diesem Benutzer
                this.authenticated = false
                this.authenticate()  // asynchron
            }

        })

        // On Connect-Error
        this.socket.on("connect_error", () => {
            console.debug(`ApiSocketService.on(CONNECT_ERROR)`)
        })

        // On Disconnected
        // "io server disconnect": The server has forcefully disconnected the socket with socket.disconnect()
        // "io client disconnect": The socket was manually disconnected using socket.disconnect()
        // "ping timeout": The server did not send a PING within the pingInterval + pingTimeout range
        // "transport close": The connection was closed (example: the user has lost connection, or the network was changed from WiFi to 4G)
        // "transport error": The connection has encountered an error (example: the server was killed during a HTTP long-polling cycle)
        this.socket.on("disconnect", (reason) => {
            console.debug(`ApiSocketService.on(DISCONNECT)[${reason}]`)
        })

        // Auf Mitteilungen vom Server horchen und an Events weiterreichen
        this.socket.on(EVENT_KEYCLOAK_USER_UPDATED, () => {
            console.debug(`ApiSocketService.on(${EVENT_KEYCLOAK_USER_UPDATED})`)
            this.keycloakUserUpdatedSubject.next()
        })
        this.socket.on(EVENT_KEYCLOAK_USER_PLAN_CHANGED, () => {
            console.debug(`ApiSocketService.on(${EVENT_KEYCLOAK_USER_PLAN_CHANGED})`)
            this.keycloakUserPlanChanged.next()
        })
        this.socket.on(EVENT_USER_UPDATED, () => {
            console.debug(`ApiSocketService.on(${EVENT_USER_UPDATED})`)
            this.userUpdatedSubject.next()
        })
        this.socket.on(EVENT_KEYCLOAK_USER_DELETED, () => {
            console.debug(`ApiSocketService.on(${EVENT_KEYCLOAK_USER_DELETED})`)
            this.keycloakUserDeleted.next()
        })
        this.socket.on(EVENT_PROJECT_UPDATED, () => {
            console.debug(`ApiSocketService.on(${EVENT_PROJECT_UPDATED})`)
            this.projectUpdated.next()
        })
        this.socket.on(EVENT_REPORT_UPDATED, () => {
            console.debug(`ApiSocketService.on(${EVENT_REPORT_UPDATED})`)
            this.reportUpdated.next()
        })
        this.socket.on(EVENT_ACTIVITY_UPDATED, (data: object) => {
            console.debug(`ApiSocketService.on(${EVENT_ACTIVITY_UPDATED})`)
            const dayIso = DateTime.fromISO(data["start_datetime_iso"]).toLocal().toISODate()
            this.activityUpdated.next(dayIso)
        })
        this.socket.on(EVENT_SETTING_UPDATED, (data: object) => {
            console.debug(`ApiSocketService.on(${EVENT_SETTING_UPDATED})`)
            const key = data["key"]
            this.settingUpdated.next(key)
        })
        this.socket.on(EVENT_TAG_UPDATED, () => {
            console.debug(`ApiSocketService.on(${EVENT_TAG_UPDATED})`)
            this.tagUpdated.next()
        })
        this.socket.on(EVENT_ROOM_JOINED, (data: object) => {
            console.debug(`ApiSocketService.on(${EVENT_ROOM_JOINED} - ${data["room"]})`)
        })

        // Log-In überwachen
        this.keycloakService.loggedInSubject.subscribe(async (loggedIn) => {
            // .pipe(distinct())
            if (loggedIn) {
                console.debug("Logged In -> authentificate...")
                await this.authenticate()
            } else {
                console.debug("Logged Out -> delete token...")
                await this.deleteToken()
            }
        })
    }


    set authenticated(value: boolean) {
        this._authenticated = value
    }


    get authenticated() {
        return this._authenticated && this.keycloakService.loggedInSubject.value && this.keycloakService.isTokenActive()
    }


    // Wartet bis die Socket-Verbindungen aufgebaut wurden (max. 10 sec.)
    private async waitConnected() {
        if (this.socket.connected) {
            return
        }
        return new Promise<void>((resolve, reject) => {

            const MAX_COUNTER = 20
            const TIMEOUT_MS = 500

            let intervalId: number
            let counter = 0

            intervalId = window.setInterval(() => {

                // Verbindung prüfen
                if (this.socket.connected) {
                    window.clearInterval(intervalId)
                    resolve()
                    return
                }

                // Timeout prüfen
                counter += 1
                if (counter > MAX_COUNTER) {
                    window.clearInterval(intervalId)
                    reject("Connection timeout")
                    return
                }

            }, TIMEOUT_MS)

        })

    }


    // Wartet bis authentifiziert wurden (max. 5 sec.)
    private async waitAuthenticated() {
        if (this.authenticated) {
            return
        }
        return new Promise<void>((resolve, reject) => {

            const MAX_COUNTER = 10
            const TIMEOUT_MS = 500

            let intervalId: number
            let counter = 0

            intervalId = window.setInterval(() => {

                // Authentifizierung prüfen
                if (this.authenticated) {
                    window.clearInterval(intervalId)
                    resolve()
                    return
                }

                // Timeout prüfen
                counter += 1
                if (counter > MAX_COUNTER) {
                    window.clearInterval(intervalId)
                    reject("Authentication timeout")
                    return
                }

            }, TIMEOUT_MS)

        })

    }


    // Wartet bis bei Keycloak angemeldet
    private async waitKeycloakLoggedIn() {
        if (this.keycloakService.loggedInSubject.value) {
            return
        }
        return new Promise<void>((resolve, reject) => {

            const MAX_COUNTER = 10
            const TIMEOUT_MS = 500

            let intervalId: number
            let counter = 0

            intervalId = window.setInterval(() => {

                // Prüfen
                if (this.keycloakService.loggedInSubject.value) {
                    window.clearInterval(intervalId)
                    resolve()
                    return
                }

                // Timeout prüfen
                counter += 1
                if (counter > MAX_COUNTER) {
                    window.clearInterval(intervalId)
                    reject("Logged in timeout")
                    return
                }

            }, TIMEOUT_MS)

        })

    }


    private async socketRequest(eventName: string, params: object = null): Promise<any> {
        console.debug(`ApiSocketService.socketRequest(${eventName})`)

        // Prüfen ob bei Keycloak eingeloggt
        try {
            await this.waitKeycloakLoggedIn()
        } catch (e) {
            this.notificationService.showWarningMessage("Not logged in.")
            throw new Error("Not logged in.")
        }

        // Warten bis die Verbindung steht
        try {
            await this.waitConnected()
        } catch (e) {
            // Es konnte keine Verbindung hergestellt werden.
            this.notificationService.showWarningMessage("Connection to the server could not be established.")
            throw new Error("Connection to the server could not be established.")
        }

        // Warten bis authentifiziert wurde
        try {
            await this.waitAuthenticated()
        } catch (e) {
            // Ausloggen
            await this.keycloakService.logoutDirect()
            this.notificationService.showWarningMessage("Authorization could not be performed.")
            throw new Error("Authorization could not be performed.")
        }

        // Argumente zusammensetzen
        let args: object = {}
        if (!!params) {
            args = {...params}
        }

        // Anfrage per Websocket (in Promise)
        try {
            return new Promise((resolve, reject) => {
                this.socket.emit(eventName, args, (response: SocketResponse) => {
                    if (response?.code === 200) {
                        if (response?.message) {
                            resolve(response?.message)
                        } else {
                            resolve(response?.result)
                        }
                    } else {
                        const errorMessage = (
                            `[Socket Request Error] ` +
                            `Request Event Name: ${eventName?.toString()}, ` +
                            `Request Params: ${params?.toString()}, ` +
                            `Request response: ${response?.toString()}, ` +
                            `Error Code: ${response?.code?.toString()}, ` +
                            `Error Message: ${response?.message?.toString()}, ` +
                            `Error Result: ${response?.result?.toString()}`
                        )
                        reject(errorMessage)
                    }

                })

            })
        } catch (errorMessage) {
            throw new Error(errorMessage)
        }

    }


    async authenticate() {
        await this.authenticatingLock.acquireAsync()

        console.debug("ApiSocketService.authenticate()")

        try {

            // Warten bis die Verbindung steht
            try {
                await this.waitConnected()
            } catch (e) {
                // Es konnte keine Verbindung hergestellt werden.
                // Meldung als Warnung (Toast) ausgeben und keinen Fehler auslösen
                this.notificationService.showWarningMessage("Connection to the server could not be established.")
                return
            }

            const token = await this.keycloakService.getToken()
            if (!token) {
                // Kein Token -> nicht eingeloggt
                // Meldung als Warnung (Toast) ausgeben und keinen Fehler auslösen
                this.notificationService.showWarningMessage("\"Not logged in.\"")
                return
            }
            const args = {
                token,
                p: this.platforms,
                b: this.build,
                v: this.version,
                d: this.deployment,
            }

            this.socket.volatile.emit("/update_token", args, (response: SocketResponse) => {
                this.authenticated = response?.code === 200
            })
        } catch (e) {
            throw e
        } finally {
            this.authenticatingLock.release()
        }

    }


    async deleteToken() {
        console.debug("ApiSocketService.deleteToken()")

        this.authenticated = false
        if (!this.socket.connected) {
            return
        }
        this.socket.volatile.emit("/delete_token")
    }


    async initUser(user_uuid: string, language: string): Promise<string> {
        console.debug("ApiSocketService.initUser()")
        return await this.socketRequest("/users/init_user", {user_uuid, language})
    }


    async updateCurrentUser(updateUser: UpdateUserParams): Promise<void> {
        console.debug("ApiSocketService.updateCurrentUser()")
        await this.socketRequest("/users/update_current_user", updateUser)
    }


    async getCurrentUser(): Promise<User> {
        console.debug("ApiSocketService.getCurrentUser()")
        return await this.socketRequest("/users/get_current_user")
    }


    async setCurrentUserPassword(setPassword: SetPasswordParams): Promise<void> {
        console.debug("ApiSocketService.setCurrentUserPassword()")
        await this.socketRequest("/users/set_current_user_password", setPassword)
    }


    async createCheckoutSession(plan: StripePlan): Promise<StripeCheckoutSession> {
        console.debug("ApiSocketService.createCheckoutSession()")
        return await this.socketRequest("/stripe/create_checkout_session", {plan})
    }


    async createCustomerPortalSession(returnPage): Promise<StripeCustomerPortalSession> {
        console.debug("ApiSocketService.createCustomerPortalSession()")
        return await this.socketRequest("/stripe/create_customer_portal_session", {return_page: returnPage})
    }


    async createProject(createProjectParams: CreateProjectParams): Promise<string> {
        console.debug("ApiSocketService.createProject()")
        return await this.socketRequest("/projects/create_project", createProjectParams)
    }


    async getProjects(): Promise<Project[]> {
        console.debug("ApiSocketService.getProjects()")
        return await this.socketRequest("/projects/get_projects")
    }


    async getProject(projectUuid: string): Promise<Project> {
        console.debug("ApiSocketService.getProject()")
        return await this.socketRequest("/projects/get_project", {project_uuid: projectUuid})
    }


    async deleteProject(projectUuid: string): Promise<void> {
        console.debug("ApiSocketService.deleteProject()")
        await this.socketRequest("/projects/delete_project", {project_uuid: projectUuid})
    }


    async updateProject(updateProjectParams: UpdateProjectParams): Promise<void> {
        console.debug("ApiSocketService.updateProject()")
        await this.socketRequest("/projects/update_project", updateProjectParams)
    }


    // Wichtig: startDateTimeUtc >= DAY < endDateTimeUtc
    async getActivitiesForTimespan(
        startDateTimeUtc: DateTime,
        endDateTimeUtc: DateTime,
        allFields: boolean = false,
        projectUuid: string = null,
        tagUuids: string[] = null
    ): Promise<Activity[]> {
        console.debug("ApiSocketService.getActivitiesForTimespan()")
        return await this.socketRequest(
            "/activities/get_activities_for_timespan",
            {
                start_datetime_iso: startDateTimeUtc.toISO(),
                end_datetime_iso: endDateTimeUtc.toISO(),
                all_fields: allFields,
                project_uuid: projectUuid,
                tag_uuids: tagUuids
            }
        )
    }


    async createActivity(createActivityParams: CreateActivityParams): Promise<string> {
        console.debug("ApiSocketService.createActivity()")
        return await this.socketRequest("/activities/create_activity", createActivityParams)
    }


    async getActivity(activityUuid: string): Promise<Activity> {
        console.debug("ApiSocketService.getActivity()")
        return await this.socketRequest("/activities/get_activity", {activity_uuid: activityUuid})
    }


    async deleteActivity(activityUuid: string): Promise<void> {
        console.debug("ApiSocketService.deleteActivity()")
        await this.socketRequest("/activities/delete_activity", {activity_uuid: activityUuid})
    }


    async updateActivity(updateActivityParams: UpdateActivityParams): Promise<void> {
        console.debug("ApiSocketService.updateActivity()")
        await this.socketRequest("/activities/update_activity", updateActivityParams)
    }


    async getRecordingActivities(): Promise<Activity[]> {
        console.debug("ApiSocketService.getRecordingActivities()")
        return await this.socketRequest("/activities/get_recording_activities")
    }


    // Holt eine, mehrere oder alle Einstellungen vom Server
    async getSettings(keys: string[]): Promise<any[]> {
        console.debug("ApiSocketService.getSettings()")
        return await this.socketRequest("/settings/get_settings", {keys})
    }


    async setSettings(keysValues: {}): Promise<void> {
        console.debug("ApiSocketService.setSettings()")
        return await this.socketRequest("/settings/set_settings", {settings: keysValues})
    }


    async createReport(createReportParams: CreateReportParams): Promise<string> {
        console.debug("ApiSocketService.createReport()")
        return await this.socketRequest("/reports/create_report", createReportParams)
    }


    async getReports(): Promise<Report[]> {
        console.debug("ApiSocketService.getReports()")
        return await this.socketRequest("/reports/get_reports")
    }


    async getReport(reportUuid: string): Promise<Report> {
        console.debug("ApiSocketService.getReport()")
        return await this.socketRequest("/reports/get_report", {report_uuid: reportUuid})
    }


    async deleteReport(reportUuid: string): Promise<void> {
        console.debug("ApiSocketService.deleteReport()")
        await this.socketRequest("/reports/delete_report", {report_uuid: reportUuid})
    }


    async updateReport(updateReportParams: UpdateReportParams): Promise<void> {
        console.debug("ApiSocketService.updateReport()")
        await this.socketRequest("/reports/update_report", updateReportParams)
    }


    async createTag(createTagParams: CreateTagParams): Promise<string> {
        console.debug("ApiSocketService.createTag()")
        return await this.socketRequest("/tags/create_tag", createTagParams)
    }


    async getTags(): Promise<Tag[]> {
        console.debug("ApiSocketService.getTags()")
        return await this.socketRequest("/tags/get_tags")
    }


    async getTag(tagUuid: string): Promise<Tag> {
        console.debug("ApiSocketService.getTag()")
        return await this.socketRequest("/tags/get_tag", {tag_uuid: tagUuid})
    }


    async deleteTag(tagUuid: string): Promise<void> {
        console.debug("ApiSocketService.deleteTag()")
        await this.socketRequest("/tags/delete_tag", {tag_uuid: tagUuid})
    }


    async updateTag(updateTagParams: UpdateTagParams): Promise<void> {
        console.debug("ApiSocketService.updateTag()")
        await this.socketRequest("/tags/update_tag", updateTagParams)
    }

}
